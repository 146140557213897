.product-carousel {
  padding: 4.8rem 0 ($space-unit * 8);
  overflow: hidden;
  @include appearAnimation();

  .swiper-container {
    overflow: visible;
  }

  .single-slide__img {
    position: relative;
    display: block;
    transition: none !important;
    opacity: 1 !important;
    overflow: hidden;
  }

  .swiper-wrapper,
  .swiper-wrapper-carousel,
  {
  @include animate(1);
}
}

.recommendations-carousel h2.Par {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-carousel__title {
  // text-align: center;
  // text-transform: uppercase;
  padding-left: 4rem;
  margin-bottom: -6rem;
}

.product-carousel__cta-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ($space-unit * 2);
}

.product-carousel__cta {
  margin: 0 1rem;
}

.product-carousel__text {
  @extend %primary-xs;
  width: 43.8rem;
  max-width: calc(100% - 3.2rem);
  margin: 2.8rem auto 0;
  text-align: center;
}

.product-carousel__carousel-container {
  margin-top: 3.2rem;
  position: relative;
  overflow: hidden;

  //border
  &.without-border {
    &:not(.labels-position--on-img) {
      .single-slide__labels {
        margin-left: ($space-unit * 4);
      }
    }
  }

  .product-tile {
    .Par {
      margin-top: 0.8rem;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      display: -webkit-box;

      &.price {
        gap: 0.8rem;
        display: flex;
        align-items: center;
        margin: 0;
      }
    }

    .variations-number {
      font-size: 1.2rem;
      color: $dark_grey;
      margin-top: 1rem;
    }
  }

  .swiper-container {
    margin-top: 3rem;
    overflow: visible;
  }

  .swiper-button-container {
    display: flex;
    z-index: 1;
    position: absolute;
    top: -3rem;
    right: 0;
    gap: 2rem;

    .icon--navigation-arrow-left.swiper-button-disabled,
    .icon--navigation-arrow-right.swiper-button-disabled {
      opacity: 0.5;
    }
  }

  /*
  .swiper-button-prev {
    &::after {
      content: '';
      @include fixedSprite('arrow-sx-red');
    }
  }
  .swiper-button-next {
    &::after {
      content: '';
      @include fixedSprite('arrow-dx-red');
      margin-left: 4rem;
    }
  }
  */
}

.product-trending__carousel-container .swiper-button-container {
  display: flex;
  z-index: 1;
  position: absolute;
  top: -4.5rem;
  right: 0;
  gap: 2rem;


  .swiper-button-disabled {
    opacity: 0.5;
  }
}

.carousel-container__navigation-button[role='button'] {
  position: absolute;
  top: -0.1rem;
  bottom: -0.1rem;
  // width: 26%;
  z-index: 2;
  left: 0;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.recommendations-carousel {
  overflow: hidden;
}

.product-trending__carousel-container {
  .recommendations-carousel {
    overflow: hidden;

    .swiper-wrapper {
      display: flex;

      .product-tile.product-tile-wrapper-element {
        width: calc((100vw - 8rem - (1.3rem * 6)) / 6);
      }
    }
  }
}

.product-carousel__carousel-container {
  .recommendations-carousel {
    overflow: hidden;

    .swiper-wrapper {
      display: flex;

      .product-tile.product-tile-wrapper-element {
        width: calc((100vw - 8rem - (2rem * 4)) / 4);
      }
    }
  }
}

.carousel-container__button__wrapper {
  @include animate(0.3);
  height: 100%;
  width: 3.2rem;

  transform: translateX(-100%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  z-index: 1;

  .button {
    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
    white-space: nowrap;

    &__text {
      opacity: 0;
      display: none;
      @include animate(0.3, 'opacity');
    }
  }

  &:hover {
    width: 14rem;

    .button {
      &__text {
        display: block;
        opacity: 1;
      }
    }
  }
}

.swiper-button-next.carousel-container__navigation-button {
  left: initial;
  right: 0;
  justify-content: flex-end;

  .carousel-container__button__wrapper {

    transform: translateX(100%);
  }
}

.show-left-arrow {
  .swiper-button-prev {
    .carousel-container__button__wrapper {

      transform: translateX(0);
    }
  }
}

.show-right-arrow {
  .swiper-button-next {
    .carousel-container__button__wrapper {

      transform: translateX(0);
    }
  }
}

.swiper-button-disabled {
  .carousel-container__button__wrapper {

    transform: translateX(-100%) !important;
  }

  &.swiper-button-next {
    .carousel-container__button__wrapper {

      transform: translateX(100%) !important;
    }
  }
}

// labels position
.labels-position--on-img {
  .single-slide__labels {
    position: absolute;
    padding: 0;
    left: ($space-unit * 4);
    bottom: ($space-unit * 4);
    color: $white;
  }
}

//hover style
.product-carousel__headline-hover {
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  text-align: center;
  text-transform: uppercase;
  color: $white;
  font-size: 3.2rem;
  line-height: 3.8rem;
  width: 90%;
  display: none;
  pointer-events: none;
}

.single-slide__img {
  &:hover {
    .product-carousel__headline-hover {
      display: block;
    }
  }
}

.swiper-pagination-progressbar {
  margin: 3rem 0 6rem 0;
  height: 0.2rem;
  width: calc(100% - 15rem);
  background: $grey;

  .swiper-pagination-progressbar-fill {
    background: $red;
    width: calc(100% - 15rem);
    height: 0.2rem;
    transform-origin: left top;
    position: absolute;
  }
}

.product-trending-container {
  .trending-grid-container {
    list-style: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2rem;
    row-gap: 4rem;
    grid-template-rows: auto;
    width: 100%;
  }
}

.complete-your-look-container {
  .complete-your-look-carousel {
    list-style: none;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2rem;
    row-gap: 4rem;
    grid-template-rows: auto;
    width: 100%;

    .product-tile-wrapper-element {
      z-index: 0;
    }
  }
}

.product-trending__carousel-container,
.product-carousel__carousel-container {
  padding: 0 4rem;
}