.discount {

  &-container {
    margin-bottom: 1.6rem;
    & .content-slot-promo {
      padding: 1.4rem 1.5rem;
      background-color: #FDEFE1;
    }

    .icon--delivery::before {
      content: '';
      @include fixedSprite('delivery-standard-16');
      margin-right: 1.2rem;
      min-width: 1.6rem;
      margin-top: 0.2rem;
    }

  }

  &-content {

    &--approaching {
      padding: 2.4rem;
      background-color: #F7F7F7;

      &-progress {
        height: 0.4rem;
        border-radius: 1rem;
        margin-top: 0.8rem;
        background-color: $green;
        width: 0;

        &-border {
          height: 0.4rem;
          border-radius: 1rem;
          width: 100%;
          margin-top: 0.8rem;
          background-color: $medium_grey;
        }
      }
    }

    &--applied {
      padding: 1.4rem 1.6rem;
      background-color: #CCEACC;

      &::before {
        margin-top: 2px;
      }
    }
  }
}