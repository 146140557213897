.product-tile {
  @include animate;

  &__image-wrapper {
    &>img {
      transform: none;
    }
  }

  &--carousel {
    @extend .product-tile;

    .product-tile__info {
      padding-right: 0;
      padding-left: 0;
      padding-top: 1.6rem;
    }
  }

  &__card {
    position: relative;
    overflow: hidden;

    .collection-in-tile {
      display: none;
    }
  }

  &__link {
    display: block;
    transition: none !important;
    opacity: 1 !important;
    text-decoration: none;
    position: relative;

    h2 {
      display: inline-block;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    [data-whatinput='keyboard'] & {
      &:focus {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: inset 0 0 0 $outline-width $outline-color-inverted;
          @include z;
        }
      }
    }
  }

  &__image {
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    right: initial;
    transform-origin: top center;
    @include animate;
  }

  &__quick-buy-panel {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-left: ($space-unit * 4);
    padding-right: ($space-unit * 4);
  }

  &__info {
    position: relative;
    padding: ($space-unit * 4) ($space-unit * 4) ($space-unit * 6);
  }

  &__name {
    @extend %primary-s;
    @include text-shorten;
    display: block;
    margin-bottom: $space-unit;
  }

  &__price {
    @extend %primary-xs;
    display: block;

  }

  &__quick-buy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 ($space-unit * 4);
    @include z;

    .button {
      width: auto;
      border: none;
      // border: $space-line solid transparent;
      box-shadow: none;
      padding: 0 $space-unit;
      margin-right: -#{$space-unit};
      margin-bottom: -#{$space-unit};

      &[class] {
        span {
          @extend %primary-xxs;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .button {
    transition: opacity 166ms $easeOutQuart, color 0.5s $easeOutQuart;
  }

  &.is-look {
    .look-toggle {
      position: relative;
      display: block;
      width: 100%;

      &:focus {
        outline: none;

        &::before {
          content: '';
          @include z;
          border: $space-line solid $white;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          box-sizing: border-box;
        }
      }

      &.no-looks {
        cursor: initial;
      }
    }

    .look-number__wrapper {
      position: absolute;
      display: flex;
      justify-content: space-between;
      padding: ($space-unit * 4);
      bottom: 0;
      left: 0;
      width: 100%;
      @include z;
      pointer-events: none;
    }

    .look-number {
      @extend %primary-xxs;
      color: $white;
    }
  }

  &.quickshopactive {
    background-color: $black;
    color: $white;

    .product-tile__image {
      // height: calc(100% - 8rem);
      width: auto;
      margin: 0 auto;
      // cursor: ew-resize;
    }

    .button {
      color: $white;
    }

    .selector--color {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $space-line;
        background-color: $white_inactive;
      }
    }
  }

  .tile-swiper-icon {
    opacity: 0;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: 50%;

    transform: translateY(50%);
    z-index: 1;
    bottom: unset;

    &.swiper-button-next {
      right: 1.2rem;
      left: unset;
    }

    &.swiper-button-prev {
      left: 1.2rem;
      right: unset;
    }
  }

  .tile-body {

    div,
    p {
      margin: 0.8rem 0;
    }
  }

  .cta--wishlist {
    opacity: 1;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0;

    &::after {
      position: static;

      transform: translateY(0);
    }
  }

  .sustainibility-icon {
    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1rem;

    &::after {
      position: static;

      transform: translateY(0);
    }
  }

  .variation-color-plp {
    display: flex;
    gap: 0.8rem;
    opacity: 0;
    visibility: hidden;
    height: 0;

    &__extra {
      color: $black;
    }

    .color-show & {
      display: flex;
    }

    a {
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        border: 0.1rem solid $disabled_txt_grey;
      }

      &.selected {
        border: 0.1rem solid $blue;
      }
    }

    img {
      height: 1.4rem;
      width: 1.4rem;
      border-radius: 50%;
    }
  }

  .cta-quick-add-to-bag {
    opacity: 0;
  }
}

@keyframes loading-pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}