.button {
  position: relative;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  @include animate(0.4, all, cubic-bezier(0.6, 0, 0.2, 1));
  cursor: pointer;
  height: 4.8rem;
  width: 100%;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 3.2rem;
  text-anchor: middle;

  &:disabled {
    background-color: $white;
    color: $disabled_txt_grey;
    cursor: none;
    pointer-events: none;

    &:after {
      opacity: 0.3;
    }

    &:before {
      opacity: 0.3;
    }
  }

  // PRIMARY & SECONDARY

  &[class*='primary'],
  &[class*='secondary'] {
    height: $button-height-desktop;
    text-align: center;

    span {
      font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;
      font-size: 1.4rem;
      line-height: 2.4rem;

      text-transform: uppercase;
      font-weight: normal;
    }

    &:focus {
      @include focus-underline;
    }
  }

  // PRIMARY

  &--primary,
  &--primary:link,
  &--primary:visited {
    background-color: $blue;
    color: $white;

    text-transform: uppercase; // force in case of lowercase properties

    &:focus {
      span {
        text-decoration-color: $white;
      }
    }

    &:hover {
      background-color: $dark_blue;
      // color: rgba($white, 0.6);
      text-decoration: none;

      &:focus {
        span {
          text-decoration-color: rgba($white, 0.6);
        }
      }
    }

    &:disabled {
      color: $disabled_txt_grey;
      background-color: $disabled_bg_grey;
      cursor: none;
      pointer-events: none;

      &:after {
        opacity: 0.3;
      }
    }
  }

  &--primary-inverted,
  &--primary-inverted:link,
  &--primary-inverted:visited {
    background-color: $white;
    color: $blue;
    text-transform: uppercase; // force in case of lowercase properties

    &:focus {
      span {
        text-decoration-color: $blue;
      }
    }

    &:hover {
      background-color: $dark_blue;
      text-decoration: none;

      &:focus {
        span {
          text-decoration-color: rgba($blue, 0.6);
        }
      }
    }
  }

  // SECONDARY

  &--secondary,
  &--secondary:link,
  &--secondary:visited {
    color: $blue;
    background-color: $white;
    border: 0.2rem solid $blue;
    text-transform: uppercase; // force in case of lowercase properties

    &:focus {
      span {
        text-decoration-color: $blue;
      }
    }

    &:hover {
      color: $dark_blue;
      border-color: $dark_blue;
      text-decoration: none;

      &:focus {
        span {
          text-decoration-color: rgba($blue, 0.6);
        }
      }
    }

    &:disabled {
      background-color: $white;
      border: solid 0.2rem $disabled_txt_grey;
      color: $disabled_txt_grey;
      cursor: none;
      pointer-events: none;

      &:after {
        opacity: 0.3;
      }
    }
  }

  &--secondary-inverted,
  &--secondary-inverted:link,
  &--secondary-inverted:visited {
    color: $white;
    border: $space-line solid $white;

    text-transform: uppercase; // force in case of lowercase properties

    &:hover {
      // box-shadow: 0 0 0 0.1rem $white;
      text-decoration: none;
      opacity: 0.6;
    }
  }

  // TERTIARY

  &[class*='tertiary'] {
    @extend %primary-xs-u;
    @include hover(0.2);

    &[data-animated] {
      opacity: 0;

      &[data-animated='true'] {
        opacity: 1;
        @include animate(0.4, 'opacity', $easeInOutQuart);
      }
    }

    text-transform: unset;
  }

  &--tertiary,
  &--tertiary:link,
  &--tertiary:visited {
    color: $black;

    font-size: 1.4rem;
    line-height: 1.29;

    border-bottom: solid 0.1rem;

    [class^='icon'] {
      color: $black;
    }

    &:hover {
      &:focus {
        text-decoration-color: rgba($blue, $hover-opacity);
      }
    }

    &:focus {
      @include focus-underline;
    }
  }

  &--tertiary-inverted,
  &--tertiary-inverted:link,
  &--tertiary-inverted:visited {
    color: $white;

    [class^='icon'] {
      color: $white;
    }

    &:hover {
      &:focus {
        text-decoration-color: rgba($white, $hover-opacity);
      }
    }

    &:focus {
      @include focus-underline;
    }
  }

  &[class*='round'] {
    height: $button-height-round;
    text-align: center;

    span {
      font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;
      font-size: 1.4rem;
      line-height: 2.4rem;

      text-transform: uppercase;
      font-weight: normal;
    }

    &:focus {
      @include focus-underline;
    }
  }

  &--round,
  &--round:link,
  &--round:visited {
    border: none;
    border-radius: 50%;
    background-color: $blue;
    padding: 1.2rem;
    width: auto;

    &:after {
      margin: 0;
    }

    &:hover {
      background-color: $dark_blue;
      text-decoration: none;

      &:focus {
        span {
          text-decoration-color: rgba($blue, 0.6);
        }
      }
    }

    &:disabled {
      background-color: $disabled_bg_grey;
      cursor: none;
      pointer-events: none;

      &:after {
        margin: 0;
      }
    }
  }

  &--round--little {
    border: none;
    border-radius: 50%;
    background-color: $blue;
    padding: 1.2rem;
    height: $button-height-round-little !important;
    width: $button-height-round-little !important;

    &::after {
      min-width: 1.6rem;
      margin: 0;
    }
  }

  // WITH ARROWS

  &--arrow-right {
    padding-right: ($space-unit * 4);

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;

      transform: translateY(-50%);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-right-black');
      @include fix-font-space(0.1rem);
    }

    &[class*='inverted'] {
      &::after {
        @include fixedSprite('arrow-right-white');
      }
    }

    @include hover(0.2);
  }

  &--arrow-left {
    padding-left: ($space-unit * 4);

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;

      transform: translateY(-50%) rotate(180deg);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-right-black');
      @include fix-font-space(0.2rem);
    }

    &[class*='inverted'] {
      &::after {
        @include fixedSprite('arrow-right-white');
      }
    }

    @include hover(0.2);
  }

  &--arrow-down {
    padding-bottom: ($space-unit * 4);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;

      transform: translateX(-50%) rotate(90deg);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-right-black');
    }

    &[class*='inverted'] {
      &::after {
        @include fixedSprite('arrow-right-white');
      }
    }

    @include hover(0.2);
  }

  &--arrow-up {
    padding-top: ($space-unit * 4);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;

      transform: translateX(-50%) rotate(-90deg);
      width: ($space-unit * 2);
      height: ($space-unit * 2);
      @include fixedSprite('arrow-right-black');
    }

    &[class*='inverted'] {
      &::after {
        @include fixedSprite('arrow-right-white');
      }
    }

    @include hover(0.2);
  }

  // LINK

  &--link {
    @extend %primary-xs;

    &:focus {
      @include focus-underline;
    }
  }

  &--fileupload {
    cursor: pointer;

    label {
      &:focus-within {
        outline: 0.3rem solid outline
      }

      cursor: pointer;
    }

    input {
      position: absolute;
      opacity: 0;
    }

    .tooltip {
      position: absolute;
      right: 1.2rem;
      display: block;
      z-index: 1;
    }
  }

  &.loading {
    overflow: hidden;

    div {
      position: absolute;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      border: 0.3rem solid $black;
      animation: 1.5s ripple infinite;
    }
  }

  &--myaccount {
    width: 100%;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6.4rem;
    border-radius: 0.4rem;
    margin-bottom: 2.4rem;
    border: 0.1rem solid $medium_grey;
  }

  //ANIMATION

  &--primary,
  &--secondary,
  &--round {
    &:hover {
      span {
        &:before {
          // opacity: 0;

          transform: translateY(-110%);
          animation: button-rotate 0.5s cubic-bezier(0.1, 0.3, 0.4, 1);
        }

        &:after {
          display: none;
          opacity: 1;

          transform: translateY(0);
        }
      }
    }

    span {
      position: relative;
      display: block;
      overflow: hidden;
      // width: 100%;
      height: auto;
      text-align: center;

      &:before,
      &:after {
        content: attr(data-label);
        display: initial;
        width: 100%;
        height: auto;

        transition: all 0.4s cubic-bezier(0.27, 0, 0.59, 0.99);
      }

      &:before {
        opacity: 1;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        transform: translateY(110%);
      }
    }
  }
}

.button--paypal {
  border-color: $black;
  @include sprite('payment-paypalbutton');
  background-size: 5.6rem 1.8rem;
  background-position: center;
}

.button--googlepay {
  @include sprite('payment-googlepayexpress');
  background-size: 5.6rem 1.8rem;
  background-position: center;
}

.button--amazonpay {
  @include sprite('payment-amazonpay');
  background-size: 8.6rem 2.8rem;
  background-position: center 0.4rem;

  // .amazonlogo {
  //   @include fixedSprite('payment-amazonpay');
  //   background-size: 8.6rem 2.8rem;
  //   background-position: center 0.4rem;
  // }
  // .chevron {
  //   @include fixedSprite('payment-amazonpay-chevron');
  //   position: absolute;
  //   right: 1rem;
  //   width: 1.6rem;
  // }
  // .paywith {
  //   position: absolute;
  //   left: 1rem;
  // }
  .adyen-checkout__amazonpay,
  .adyen-checkout__amazonpay__button {
    width: 100% !important;
    height: 3.6rem !important;
    overflow: hidden;
    opacity: 0.2;
  }
}

[data-whatintent='keyboard'] {

  .button.button--paypal,
  .button--googlepay,
  .button--amazonpay,
  .button--applepay {
    &:focus {
      outline: 0.1rem inset $black !important;
      outline-offset: -0.4rem;
    }
  }
}

.use-location {
  position: relative;
  padding: $space-unit;
  margin: 0 $space-unit;

  &::before {
    @include fixedSprite('pin-full');
    content: '';
    display: block;
    margin-top: -0.2rem;
  }
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes animation-underline {
  0% {

    transform: translateX(0);
  }

  50% {

    transform: translateX(100%);
  }

  51% {

    transform: translateX(-100%);
  }

  100% {

    transform: translateX(0%);
  }
}

@keyframes button-rotate {
  0% {

    transform: translateY(0);
  }

  50% {

    transform: translateY(-105%);
  }

  51% {

    transform: translateY(105%);
  }

  100% {

    transform: translateY(0%);
  }
}

.cta {
  position: relative;
  color: $blue;

  &:disabled {
    opacity: 0.3;
    cursor: none;
    pointer-events: none;
  }
}

.cta--animated {
  @extend .cta;
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;

  position: relative;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;

  &:before {
    content: '';
    display: block;
    border-top: 0.1rem solid $blue;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &:hover,
  &:focus {
    &:before {
      border-top: 0.1rem solid $dark-blue;
      animation: animation-underline 1s cubic-bezier(0.4, 0, 0.4, 1);
    }
  }
}

.cta--animated-white {
  @extend .cta;
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;

  position: relative;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
  color: $white;

  &:before {
    content: '';
    display: block;
    border-top: 0.1rem solid $white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &:hover,
  &:focus {
    &:before {
      border-top: 0.1rem solid $white;
      animation: animation-underline 1s cubic-bezier(0.4, 0, 0.4, 1);
    }
  }
}

.cta--editorial-animated {
  position: relative;
  width: fit-content;
  overflow: hidden;
  display: flex;
  padding-bottom: 0.1rem;

  &::before {
    content: '';
    width: 0.6rem;
    height: 0.6rem;
    background-color: $red;
    position: absolute;
    top: 50%;
    left: 0;

    transform: translateY(-50%) scale(1);

    transition: all 0.4s cubic-bezier(0.1, 0.3, 0.4, 1);
  }

  span {
    position: relative;
    padding-bottom: 0.2rem;

    transition: all 0.4s cubic-bezier(0.1, 0.3, 0.4, 1);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: $red;
      opacity: 0;
      transition: opacity 0.4s, transform 0.4s;

      transform: translate3d(-100%, 0, 0);
    }
  }

  &:hover {
    &::before {

      transform: translateY(-50%) scale(0);

      transition: all 0.4s cubic-bezier(0.1, 0.3, 0.4, 1);
    }

    span {

      transform: translateX(-1.4rem);

      transition: all 0.4s cubic-bezier(0.1, 0.3, 0.4, 1);

      &::after {
        opacity: 1;

        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.button--arrow-flow {
  width: 1.6rem;
  height: 1.6rem;
  display: grid;
  overflow: hidden;
}

.button--double-arrow:hover:after {

  transform: translate(100%);
}

.arrow--icon {
  width: 1.6rem;
  height: 1.6rem;
  overflow: hidden;
  position: absolute;
  margin-left: 0.9rem;
}

.arrow--button,
.arrow--button+* {
  display: inline-block;
}

.arrow--button:hover+.arrow--icon:after,
.arrow--button:hover+.arrow--icon:before {

  transform: translateX(1.6rem);
}

.arrow--icon:before,
.arrow--icon:after {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg fill='%23232323'%3e%3cg%3e%3cg%3e%3cg%3e%3cg%3e%3cpath d='M9.53 1.813L15.718 8 9.53 14.187l-1.06-1.06 4.375-4.377H0v-1.5h12.845L8.47 2.873z'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");

  transition: all 0.4s cubic-bezier(0.6, 0, 0.2, 1);
  width: 1.6rem;
  position: absolute;
}

.arrow--icon:after {
  left: 0;
}

.arrow--icon:before {
  left: -1.6rem;
}

.arrow--icon:hover:before,
.arrow--icon:hover:after {

  transform: translateX(1.6rem);
}


.button--editorial {
  width: fit-content;
  padding: 2rem;
  background-color: $light_grey;
  display: flex;
  flex-direction: column-reverse;

  & ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    display: flex;
    flex-direction: column-reverse;
    float: right;
  }

  & li {
    text-align: center;
    background-color: $white;
    padding: 0;
  }

  & label,
  & li a {
    @extend .button !optional;
    width: 100%;
    color: $blue;
    text-transform: uppercase;
    height: $button-height-desktop;
    text-align: center;
    text-decoration: none;

    &:hover {
      color: $dark_blue;
    }
  }

  & label {
    margin-top: 1.6rem;
    z-index: 10;
    background-color: $white;
  }

  & label:after {
    content: "";
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.75 2v5.25H14v1.5H8.75V14h-1.5V8.75H2v-1.5h5.25V2z' fill='%230C1C3E' fill-rule='evenodd'/%3e%3c/svg%3e") no-repeat;
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;
    vertical-align: text-top;
    margin-left: 0.8rem;

    transition: transform .6s cubic-bezier(0.27, 0, 0.59, 0.99);
  }

  & input {
    display: none;
  }

  & li {
    box-shadow: inset 0 -0.1rem 0 0 $medium_grey;
  }

  & input:checked+label:after {
    transform: rotate(180deg) scale(0);

    transition: transform .6s cubic-bezier(0.27, 0, 0.59, 0.99);
  }

  & input:checked+label:before {
    content: '';
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14 7.25v1.5H2v-1.5z' fill='%230C1C3E' fill-rule='evenodd'/%3e%3c/svg%3e") no-repeat;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    right: 3.2rem;
    transform: rotate(180deg) scale(1);

    transition: transform .6s cubic-bezier(0.27, 0, 0.59, 0.99);
  }

  & input+label:before {
    content: '';

    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14 7.25v1.5H2v-1.5z' fill='%230C1C3E' fill-rule='evenodd'/%3e%3c/svg%3e") no-repeat;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    right: 3.2rem;

    transition: transform .6s cubic-bezier(0.27, 0, 0.59, 0.99);
  }

  & input:checked~ul li {

    transform: translateY(0);

    transition: transform 0.6s cubic-bezier(0.27, 0, 0.59, 0.99);
  }
}