#account-thankyouregister {
  .register-back {
    display: none;
  }
  .product-carousel {
    &-wrapper {
      padding: 6rem 0;
      background-color: $white;
      margin-top: 4.8rem;
    }

    &__title {
      color: $blue;
    }

    &__carousel-container {
      margin-bottom: 0;

      .swiper-container {
        z-index: unset;
        overflow: visible;
      }
    }
  }
}
.thankyouLogin {
  &__container {
    max-width: 26vw;
    margin: 6.4rem auto 4.8rem;
    text-align: center;
  }

  &__btn {
    max-width: fit-content;
    margin: auto;
  }
}
