$colors: (
/* ---------COLORI IN INGLESE--------- */
  'GREY'				:	#808080,
  'GRAY'				:	#808080,
  'RED'					:	#FF0000,
  'BLUE'				:	#0000FF,
  'BEIGE'				:	#F5F5DC,
  'BLACK'				:	#000000,
  'PINK'				:	#FFC0CB,
  'GREEN'				:	#008000,
  'BROWN'				:	#A52A2A,
  'PURPLE'			:	#800080,
  'YELLOW'			:	#FFFF00,
  'ORANGE'			:	#FFA500,
  'GOLD'				:	#DAA520,
  'SILVER'			:	#C0C0C0,
  'JASPER'			:	#D73B3E,
  'CLEAR'       : #ffffff,
  'DENIM'       : #1560bd,
  'LIGHT.BLUE'  : #ADD8E6,
  'WHITE'       : #f4f4f4,
/* ---------COLORI IN ITALIANO--------- */
  'GRIGIO'			:	#808080,
  'ROSSO'				:	#FF0000,
  'BLU'				  :	#0000FF,
  'NERO'				:	#000000,
  'ROSA'				:	#FFC0CB,
  'VERDE'				:	#008000,
  'MARRONE'			:	#A52A2A,
  'VIOLA'			  :	#800080,
  'GIALLO'			:	#FFFF00,
  'ARANCIONE'		:	#FFA500,
  'ORO'				  :	#DAA520,
  'ARGENTO'			:	#C0C0C0,
  'TRASPARENTE' : #ffffff,
  'AZZURRO'     : #ADD8E6,
  'BIANCO'      : #f4f4f4,
);

@each $name, $var in $colors {
  .color-swatch-circle span.#{$name} {
    background-color: $var;
  }
}

.color-swatch-circle span.MULTI, .color-swatch-circle span.MULTICOLOR {
  background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
}