.quickview-modal .tingle-modal-box {
  height: 70%;
  bottom: 0;
  top: unset;
  width: 100%;
  border-radius: 0.4rem 0.4rem 0 0;

  .tingle-modal-box__content .quickview-content {
    padding: 0;

    .selector__menu {
      padding: 0;

      .size-selector-label-wrapper {
        border: 0;

        .size-default-wrapper {
          display: none;
        }

        .custom-select.select-size {
          margin: 0 1.2rem;
        }
      }
    }
  }
}

.quickview-modal .findinstore-wrapper.tingle-modal-box {
  height: 85%;
}

.cart-remove-product .tingle-modal .tingle-modal-box {
  max-width: 90vw;
}


.header-enhanced-login .tingle-modal-box {
  max-width: unset;
  margin-bottom: 0;
  border-radius: 0;
}