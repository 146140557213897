.heading-block {
  margin: 4rem 0;
  .title,
  .subtitle {
    margin: 0 auto;
    text-align: center;
    font-weight: 300;
    margin-bottom: 1rem;
    line-height: 1.4;
  }
  .title {
    font-size: 5.4rem;
  }
  .subtitle {
    font-size: 1.8rem;
  }
}
