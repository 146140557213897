.modal {
  min-width: 60%;

  // &.size-modal {
  //   height: 80vh;
  //   width: 64vw;
  // }
  &.modal--small {
    @include modal-width(4);
  }
}
