.form-select {
  select {
    border-radius: 0.4rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: $black;
    line-height: 1.8rem;
    padding: 1.5rem 1.2rem;
    background-color: $white;
    width: 100%;
    border: 0.1rem solid $medium_grey;
    height: 4.8rem;

    transition: all 0.5s $easeOutQuart, outline 0s, border 0s, border-color 0s;

    // since the select will always have a default
    &~label {
      top: 0.6rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    &:disabled {
      border-color: rgba($grey_input, $input-disabled-opacity);
      cursor: none;
      pointer-events: none;
      opacity: $input-disabled-opacity;
    }
  }

  .invalid-feedback {
    text-align: right;
  }
}