.checkbox .switch {
  height: 3.2rem;
  width: 8.4rem;
  background-color: $blue;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .value {
    font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;
    width: 4rem;
    height: 2.8rem;
    font-size: 1.4rem;
    line-height: 2.8rem;

    text-transform: uppercase;
    border-radius: 0.4rem;
    text-align: center;
    color: #fff9;

    transition: all 0.4s ease;

    &.active {
      color: $blue;
      background-color: $white;
    }
  }
}

.switch-row,
.mini-form .row.switch-row {
  display: flex;
  align-items: center;
  gap: 2.4rem;

  .checkbox {
    margin: 0;
  }
}