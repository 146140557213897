// touch area
$target-size-mobile: 4rem;
$target-size-desktop: 3.2rem;
$outline-width: 0.1rem;
$outline-color: $black;
$outline-color-inverted: $white;
$outline-color-alt: #f8e28f;
$outline-radio-size: 2.4rem;
$outline-checkbox-size: 2.4rem;

// global

$hover-opacity: 0.7;

// checkbox / radio
$radio-size: 1.6rem;
$checkbox-size: 1.6rem;

// swatch
$swatch-size-mobile: 2rem;
$swatch-size-desktop: 1.6rem;

// button
$button-height-desktop: 4.8rem;
$button-height-round: 4rem;
$button-height-round-little: 3.2rem;

// input
$input-height: 4.8rem;
$input-disabled-opacity: 0.5; // sulla styleguide #FBFBFB

// size & color selectors
$selector-intile-height: 3.8rem;
$selector-inpage-height-mobile: 4.6rem;
$selector-inpage-height-desktop: 3.6rem;

// size selector in PLP Tile
$selector-size-intile-height: $selector-intile-height;
$selector-size-intile-cols: 6;
$selector-size-intile-rowgap: 1.2rem;
$selector-size-intile-padding: 1.1rem 0;

// size selector in PDP, Reserve in boutique modal, shop by look
$selector-size-inpage-height-mobile: $selector-inpage-height-mobile;
$selector-size-inpage-cols-mobile: 6;
$selector-size-inpage-rowgap-mobile: 2rem;
$selector-size-inpage-padding-mobile: 1.6rem 0;

$selector-size-inpage-height-desktop: $selector-inpage-height-desktop;
$selector-size-inpage-cols-desktop: 7; //note that children sizes may be long, 10 cols are too narrow
$selector-size-inpage-rowgap-desktop: 2rem;
$selector-size-inpage-padding-desktop: 1.1rem 0 6rem;

// size selector in Cart
$selector-size-inline-cols-desktop: 7;
$selector-size-inline-rowgap-desktop: 2rem;

// color selector in PLP Tile
$selector-color-intile-height: $selector-intile-height;

// color selector in PDP, Reserve in boutique modal
$selector-color-inpage-height-mobile: $selector-inpage-height-mobile;
$selector-color-inpage-height-desktop: $selector-inpage-height-desktop;

// storelocator map fixed height
$storelocator-height: calc(var(--viewport-height) - 12.8rem);
$storelocator-list-height: calc(var(--viewport-height) - 21.2rem);
$storelocator-height-desktop: calc(100vh - 15.6rem);

// easing
$easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
$easeInQuart: cubic-bezier(0.5, 0, 0.75, 0);
$easeInOutQuart: cubic-bezier(0.76, 0, 0.24, 1);
$pseudoInQuart: cubic-bezier(0.76, 0, 0.88, 0.5);
$pseudoOutQuart: cubic-bezier(0.12, 0.5, 0.24, 1);
