.minibag {
  display: flex;
  position: fixed;
  top: var(--ticker-height);
  right: 0;
  flex-direction: column;
  width: 41.4rem;
  height: calc(var(--viewport-height) - var(--ticker-height));
  padding: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;

  transition: all .4s;
  z-index: 1;
  background-color: white;

  &.empty {
    .minibag__header-wrapper {
      position: absolute;
      width: 100%;
    }

    .minibag-empty-wrapper {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      row-gap: 2.4rem;
      padding: 2.4rem;
    }

    .minibag__buttons__wrapper-empty,
    .minibag-content-wrapper-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1.6rem;
    }

    .minibag__buttons__wrapper-empty {
      .button {
        width: 100%;
      }
    }
  }

  &.minibag-collapse {

    transform: translateY(8.8rem);
  }

  .minibag-content-wrapper {
    padding: 0 2.4rem;
    display: flex;
    flex-direction: column;
    height: calc(100% - 22.5rem);
  }

  &__title {
    display: flex;
    justify-content: space-between;
    padding-bottom: $space-unit * 2;
    margin-bottom: 3.2rem;

    &--empty {
      display: flex;
      justify-content: space-between;

      >p {}
    }
  }

  .icon--close-16 {
    position: absolute;
    top: 2.8rem;
    right: 2.4rem;
    z-index: 2;
  }

  &__mobile {
    padding-bottom: 1.4rem;
  }

  &__header {
    padding: 2rem 2.4rem;
    border-bottom: 0.1rem solid $light_grey;

    &-wrapper {
      margin-bottom: 2.4rem;
    }
  }

  &__mobile-label {
    @include border('top');
    padding: 2rem 0 1.6rem 0;
  }

  &__total {
    display: flex;
    justify-content: space-between;
    padding: $space-unit * 4 0;

    &>* {
      text-transform: initial;
    }
  }

  &__buttons {
    @include animate;

    .button:not(:last-child) {
      margin-bottom: $space-unit * 2;
    }
  }

  &__productcontainer {
    padding-bottom: $space-unit * 0.5;

    &:not(:last-of-type) {
      @include border('bottom', 0.1rem);
      margin-bottom: $space-unit * 4;
      padding-bottom: $space-unit * 4;
    }
  }

  &__product {
    display: flex;

    &[data-offline-product='true'] {
      .minibag-outOfStock {
        color: $red;
        padding-top: 0.8rem;
      }

      .minibag__product__price,
      .minibag__product__title .label-title,
      .minibag__product__image {
        opacity: 0.3;
      }
    }

    &__image {
      flex-basis: 11rem;

      .product-image {
        height: 14.4rem;

        &:before {
          content: initial;
        }
      }
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: $space-unit * 4;
      position: relative;
    }

    &__columns {
      display: flex;
      justify-content: space-between;

      .label-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__price {
      white-space: nowrap;
      margin-top: 0.4rem;
    }

    &__label {
      display: block;
      text-align: right;
      color: $black_secondary;
      line-height: 1.3;
    }

    &__info {
      color: $dark_grey;
      margin-top: 0.4rem;

      p {
        margin-top: $space-unit;
      }
    }

    &__actions {}
  }

  &__list {
    overflow-y: auto;
    flex-grow: 1;
    margin-bottom: 1.4rem;
    padding-right: ($space-unit * 4);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__bottom__wrapper {
    position: absolute;
    bottom: 2.4rem;
    width: 100%;
    border-top: 0.1rem solid $light_grey;
  }

  &__bottom {
    width: 36.6rem;
    margin: auto;
  }

  &__buttons__wrapper {
    @include animate;
    display: flex;
    gap: 1.6rem;

    .button {
      flex: 1;
      width: 100%;
      overflow: hidden;

      &:first-child:not(:last-child) {
        margin-right: 1.6rem;
      }
    }

    &.hide {
      transition-delay: 0.2s;
      visibility: hidden;
    }
  }

  &__expresspayment {
    position: absolute;
    width: 100%;
    min-height: 100%;
    background-color: $white;
    top: 0;
    left: 0;

    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
    @include animate;

    &.open {
      transition-delay: 0.2s;

      transform: translateX(0);
      visibility: visible;
      opacity: 1;
    }

    &__intro.button {
      width: 100%;
      padding-right: 1.6rem;
      padding-bottom: 0.8rem;
      text-align: center;
      font-size: 1.6rem;
      line-height: 2rem;
      text-transform: capitalize;
    }

    #container-paywithgoogle {
      display: none;
    }

    button {
      display: block;

      &.apple-pay-button,
      &.apple-pay-button:hover {
        border-radius: 0;
        margin-top: 0;
      }
    }
  }
}

[data-react='minicart-app'] {
  .overlay {
    z-index: 0;
  }

  .icon--bag {
    span {
      display: inline;
    }
  }
}

#cart-icon {
  &[aria-expanded='true'] {
    +#minibag-container {
      .overlay {
        opacity: 0.5;
        visibility: visible;
      }

      .minibag {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.feedbackbag-wrapper {
  &[data-visible='true'] {
    #feedbackbag-container {
      .overlay {
        opacity: 0.5;
        visibility: visible;
      }

      .overlay-feedbackbag {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: calc(100vh);

        transition: all .4s ease-out;

        &.feedbackbag-translate {

          transform: translateY(10rem);
        }

        .feedbackbag {
          position: absolute;
          bottom: 3rem;
          right: 5rem;
          background: $white;
          padding: 2.4rem;
          border-radius: 0.4rem;
          width: 41rem;

          .minibag__productcontainer {
            border: 0;
          }

          .feedbackbag__buttons__wrapper {
            display: flex;

            .button {
              flex: 1;
              width: 100%;
              overflow: hidden;

              &:first-child:not(:last-child) {
                margin-right: 1.6rem;
              }
            }
          }
        }
      }
    }
  }
}

// #cart-icon:not([aria-expanded='true']) {
//   + #minibag-container {
//     .minibag__expresspayment.open {
//       visibility: hidden;
//     }
//   }
// }
// #minibag-container .overlay {
//   pointer-events: none;
// }