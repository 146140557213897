.overlay .open {
  nav {
    visibility: visible;
    background-color: $white;
  }
}

header {
  .container-nav {
    padding: 0;
    height: 0;
    width: 100%;

    &.open{
      .nav-logo{
        visibility: hidden;
      }
    }
  }

  .logo-header {
    img {
      width: 4.3rem;
    }
  }

  nav {
    width: unset;
    max-width: unset;
    height: calc(var(--viewport-height) - 9.2rem);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    background-color: transparent;
    @include animate(0.5);
  }

  .image-column {
    padding: 3.2rem 0 0;
  }

  .header-account-link {
    text-decoration: none;
    position: relative;
    order: 3;


    transform: translateX(100%);
    @include animate(0.5);

    .header-account-banner {
      background-color: $blue;
      padding: 3.2rem 2.4rem;
      color: $white;
    }
  }

  .header-links-component {
    position: relative;
    display: flex;
    flex-direction: column;
    order: 4;
    color: $blue;
    padding: 1.6rem 0;


    transform: translateX(100%);
    @include animate(0.5);

    a {
      text-decoration: none;
    }

    .button {
      padding: 1.6rem 2.4rem;
    }
  }

  .menu-actions {
    background: transparent;
    width: 100%;
    position: absolute;
    top: 0;

    // z-index: 1;
    .menu-icon button {
      padding: 2rem 1.6rem;
      width: 4.8rem;
    }

    .menu-icon:first-child {
      border-left: none;
    }

    .menu-icon.wrapper-menu-search {
      order: 2;
    }

    .menu-icon.account-wrapper {
      order: 3;
      position: absolute;
      right: 4.8rem;
    }

    .menu-icon.wrapper-menu-minicart {
      order: 4;
      position: absolute;
      right: 0;
    }

    .menu-icon.wrapper-menu-hamburger {
      order: 1;
    }
  }

  .nav-wrapper {
    padding: 0;
    display: block;
    height: 6.1rem;

    transform: translateY(0);


    transition: all 0.4s;

    &.collapse-translate {

      transform: translateY(-6.1rem);
    }
  }

  .nav-logo {
    position: relative;
    height: 6rem;
    width: fit-content;
    margin: auto;
    z-index: 1;
    padding: 0;
    
    & a {
      padding: 1.4rem;

      & svg {
        margin: auto;
        height: 3.2rem;
        width: auto;
      }
    }
  }

  .header-accordion {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0 2rem;
    width: 100%;
  }

  .header-accordion::after {
    opacity: 1;
    visibility: visible;
  }

  .experience-component .experience-widget_1_editorial {
    width: 100%;
    margin-top: 3.2rem;
  }

  .header-accordion::after {
    content: '';
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg fill='%23222'%3e%3cg%3e%3cg%3e%3cg%3e%3cpath d='M4.939 3L6 1.939 12.061 8 6 14.061 4.939 13l5-5z'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e") no-repeat;
    width: 1.7rem;
    height: 1.7rem;
    background-size: 1.7rem 1.7rem;
    display: inline-block;

    transition: transform 0.3s ease-in-out;
    transform: rotateZ(270deg);
  }

  .closed.header-accordion::after {
    transform: rotateZ(90deg);
  }

  .header-accordion.closed~ul {
    height: 0;
    opacity: 0;
    padding-bottom: 0;
  }

  .menu-wrapper {
    position: relative;
    order: 2;
    overflow: visible;
    right: 0;
    background-color: $white;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 3.2rem;
    top: 0;
    height: max-content;
    padding: 3.2rem 2.4rem;
    margin-top: 0;

    transform: translateX(100%);

    @include animate(0.5);
    width: 100%;
    max-width: initial;
    opacity: 0;
    visibility: hidden;
  }

  .menu-item button.menu-heading[aria-expanded='false']+.wrapper-submenu .container-submenu {
    display: none;
  }

  .container-submenu {
    padding: 0 0 3.2rem;
  }

  .menu-footer {
    position: relative;
    order: 1;
    display: flex;
    overflow: visible;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    visibility: hidden;
    @include animate(0.5);


    transform: translateX(100%);
    background-color: $white;
    width: 100%;
    height: max-content;
    box-shadow: 0 0.4rem 2rem 0 rgba(0, 0, 0, 0.08);
    top: 0;
    z-index: 1;

    .experience-component {
      width: 100%;
      margin-top: 0;
    }

    & .header--promo {
      padding: 3.2rem 2.4rem;

      &>div {
        display: block;
      }

      .header-promo-text {
        color: $light_orange;
        text-transform: uppercase;
      }

      & .promo-title {
        color: $light_orange;
        text-transform: uppercase;
        margin-bottom: 0.8rem;
      }

      & .promo-description {
        color: $light_orange;
        text-transform: uppercase;
        margin-bottom: 2.4rem;
      }

      & .header-promo-code {
        color: $white;
        border: 0.2rem solid $white;
        margin-top: 2.4rem;
        margin-left: auto;
        cursor: pointer;

        & .CTA {
          margin: auto;
          margin-right: 0.8rem;
          padding: 1.2rem 3.2rem;
          text-transform: uppercase;
          width: fit-content;

          &::before {
            vertical-align: sub;
          }
        }
      }
    }

    .item-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 2rem;
      text-decoration: none;

      .label {
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.33;
        margin-top: 0.4rem;
      }
    }
  }

  .wrapper-submenu {
    padding: 0 2.4rem 3.2rem;
    height: calc(100vh - 15.6rem);
    overflow: auto;
  }

  .visually-hidden-d {

    &,
    a {
      display: block;
      @include del-link;
      font-size: 2.4rem;
      line-height: 3.2rem;
      font-weight: 300;
    }

    +.main-submenu>.submenu-group:nth-child(1)>.submenu-heading {
      margin-top: 4rem;
    }
  }

  .main-submenu {
    grid-template-columns: repeat(2, 47%);
    gap: 0 6%;
  }

  .open {

    .menu-wrapper,
    .menu-footer,
    .header-account-link,
    .header-links-component {
      opacity: 1;
      visibility: visible;


      transform: translateX(0);
    }
  }

  .menu-heading {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.featured {
      color: $red;
    }

    &::after {
      content: '';
      @include fixedSprite('arrow-right');
    }
  }

  button.text--content {

    transform: translateY(-2rem);
    color: $blue;
  }

  .first-level {
    [data-menu-back] {
      opacity: 1;
      visibility: visible;
      align-items: center;
    }

    .menu-heading,
    .header--promo {
      opacity: 0;
      visibility: hidden;
    }

    .menu-footer,
    .header-account-link,
    .header-links-component {
      opacity: 0;
      visibility: hidden;
      display: none;
    }

    .menu-heading[aria-expanded='true'] {
      +.wrapper-submenu {
        opacity: 1;
        visibility: visible;


        transform: translateX(0);
      }
    }

    .menu-wrapper {
      margin-top: 6.4rem;
      height: calc(var(--viewport-height) - 6.4rem);
      transition: translateY 0s;
      overflow: hidden;
    }

    button.text--content {

      transform: translateY(0);
    }
  }

  .submenu-heading {
    margin-bottom: 0;
  }

  .submenu-group:not(.submenu-group-empty) {
    border-bottom: 0.1rem solid $medium_grey;
  }

  .submenu-group:is(:last-child) {
    border-bottom: 0.1rem solid $medium_grey;
  }

  .submenu-group {
    grid-column: span 2;
    width: calc(100vw - 4.8rem);

    &+.submenu-group-empty {
      border-bottom: 0;
      border-top: 0;
      height: 0;
    }

    &.closed {
      border-bottom: 0.1rem solid $dark_grey;
    }

    ul {
      display: grid;
      gap: 3.2rem;
      padding-bottom: 3.2rem;

      li {
        margin-bottom: 0;
      }
    }
  }

  .navigation:not(.first-level) .wrapper-submenu {
    display: none;
  }

  a.CTA.menu-heading:not([aria-expanded='true'])+div {
    display: none;
  }
}