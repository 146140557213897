@font-face {
  font-family: "HelveticaNeue-Light";
  src: url("../fonts/5664085/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2") format("woff2"), url("../fonts/5664085/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("../fonts/5664093/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"), url("../fonts/5664093/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("../fonts/5664093/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"), url("../fonts/5664093/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeue-Medium";
  src: url("../fonts/5664103/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"), url("../fonts/5664103/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff");
}

html {
  letter-spacing: 0.4px;
  -webkit-text-size-adjust: none;
}

.T1blackcentre {
  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 5.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;

  text-align: center;
  color: var(--black);
}

.T3blackleft {
  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 2.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.144;

  color: var(--black);
}

.paragraphgraycentre {
  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;

  text-align: center;
  color: #6f6f6f;
}

.T-display {
  font-size: 6.4rem;
  line-height: 7.2rem;

}

.T1 {
  font-size: 5.6rem;
  line-height: 6.4rem;

}

.T2 {
  font-size: 4.8rem;
  line-height: 5.6rem;

}

.T3 {
  font-size: 4rem;
  line-height: 4.8rem;

}

.T4 {
  font-size: 3.2rem;
  line-height: 4rem;

}

.T5 {
  font-size: 2.4rem;
  line-height: 3.2rem;

}

.T6 {
  font-size: 2rem;
  line-height: 2.8rem;

}

.Par {
  font-size: 1.4rem;
  line-height: 2rem;

  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;

  &-small {
    font-size: 1.2rem;

    line-height: 1.6rem;
    font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
  }

  &-mini {
    font-size: 1rem;
    line-height: 1.6rem;
  }

  &-big {
    font-size: 1.6rem;

    line-height: 2.4rem;
    font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
  }

  &-desc {
    font-size: 3.2rem;

    line-height: 4rem;
    font-family: 'HelveticaNeue-Light';

    &2 {
      font-size: 2.4rem;

      line-height: 3.2rem;
      font-family: 'HelveticaNeue-Light';
    }
  }
}

.Val1 {
  font-size: 2rem;
  line-height: 2rem;

}

.Val2 {
  font-size: 1.4rem;
  line-height: 2rem;

}

.CTA {
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;

  text-decoration: none;

  &-u {
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;

    text-transform: uppercase;
    text-decoration: none;
  }
}

.promo-text {
  font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;

}

.Caption {
  font-size: 1.4rem;
  line-height: 2rem;

  text-transform: uppercase;
  font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;
}

.Caption-l {
  font-size: 1.4rem;
  line-height: 2rem;

  font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;
}

.underline {
  text-decoration: underline;
}

.text {
  font-family: 'HelveticaNeue', Helvetica, Arial, sans-serif;

  &-light {
    font-family: 'HelveticaNeue-Light', Helvetica, Arial, sans-serif;
    font-weight: lighter;
  }

  &-medium {
    font-family: HelveticaNeue-Medium, Helvetica, Arial, sans-serif;
  }

  &-bold {
    font-family: 'HelveticaNeue', Helvetica, Arial, sans-serif;
    font-weight: 700;
  }

  &-normal {
    font-family: 'HelveticaNeue', Helvetica, Arial, sans-serif;
    font-weight: normal;
  }

  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
}

.bold {
  font-weight: bold;
}

.no-underline {
  text-decoration: none;
}