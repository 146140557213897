//spacings
$space-unit: 4px;
$space-line: 2px;
$space-line2: 2px;
$header-menuheight-desktop: 9rem;
$header-height-desktop: 9rem;
$header-height-mobile: 6rem;
$footer-clientservice-height-desktop: 31.6rem; // arbitrary height, could vary depending on content
$footer-clientservice-height-mobile: 57rem; // arbitrary height, could vary depending on content
$ticker-bar: 3.2rem;
$gradient-height: 12rem;
$spacing-multiplier: (1, 2, 3, 4, 5, 6, 8, 10, 15, 20, 30);
