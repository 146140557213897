.radio {
  &__icon {
    [class^='icon'] {
      margin-left: initial;
      margin-right: ($space-unit * 2);
    }

    > span {
      flex: 1 0 100%;
      margin-top: 0.8rem;
    }
  }
}
