.login-modal {
  .modal__body {
    max-height: unset;
    overflow: auto;

    form {
      .button-registration-section {
        margin-top: 3.2rem;

        h4 {
          padding-top: 3.2rem;
        }
      }
    }
  }
}

.bag-login-slot-wrapper {
  padding: 1.6rem 2.4rem;
  margin-bottom: 3.2rem;

  .bag-login-slot {
    padding: 0;
  }

  .cart-loyalty-coupons-wrapper {
    flex-direction: column;
    align-items: center;
  }
}

.cartLoginWrapper {
  padding: 1.6rem;
  display: flex;

  .loginSlotButton {
    margin-right: unset;
  }

  .bag-login-slot {
    padding: 0;
  }

  .cart-loyalty-coupons-wrapper {
    flex-direction: column;
    align-items: center;
  }

  &-buttonWrapper {
    .button {
      padding: 0 1.2rem;
    }
  }
}

