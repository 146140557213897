.embeddedServiceSidebarMinimizedFabDefaultUI button,
.embeddedServiceSidebarMinimizedFabDefaultUI button.fab,
.embeddedServiceSidebarMinimizedFabDefaultUI button.fab:hover,
.embeddedServiceSidebarMinimizedFabDefaultUI button.fab:focus,
.waitingCancelChat {
  background: #0C1C3E;
  background-color: #12253c !important;
}

/*fumetti operatori*/
.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.agent.plaintextContent,
.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent {
  background: #ffffff !important;
  border: 0.1rem #d8d8d8 solid;
  color: #000000 !important;

}


.embeddedServiceLiveAgentStateChatItem.chatMessage {
  background: unset;
}

/*fumetto agnte*/
.embeddedServiceLiveAgentStateChat .messageArea.smoothScroll,
.embedded-service .fab {
  background: unset;
}

.embeddedServiceSidebarMinimizedFabDefaultUI .dismiss-button {
  border-style: none;
  background-color: #12253c;
  color: $white;
}

.embeddedServiceSidebarMinimizedFabDefaultUI .message {
  background-color: #ffff;
  border-style: none;
}

.embeddedServiceSidebarMinimizedFabDefaultUI .fab {
  border-color: unset;
}

.embeddedServiceSidebarMinimizedFabDefaultUI {
  width: 5.7rem;
}

/*cta*/
.embeddedServiceSidebarButton,
.embeddedServiceSidebarButton:not(:disabled):hover,
.embeddedServiceSidebarButton:not(:disabled):focus,
.embeddedServiceSidebarButton.uiButton--inverse,
.embeddedServiceSidebarButton.uiButton--inverse:hover,
.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):hover,
.embeddedServiceSidebarButton.uiButton--inverse:not(:disabled):focus {
  background: #12253c !Important;
}


/*testo cta*/
.embeddedServiceSidebarButton,
.embeddedServiceSidebarButton.uiButton--inverse .label,
.embeddedServiceSidebarButton .label,
.embeddedServiceLiveAgentStateChat .uiButton--default:focus .label,
.embeddedServiceLiveAgentStateChat .uiButton--default:hover .label {
  background: #12253c !important;
  color: #FFFFFF !important;
}

.embeddedServiceIcon svg {
  fill: $white;
  width: 2.2rem;
  max-width: 1.8em;
  max-height: 1.8em;
}

.help .embeddedServiceIcon svg {
  width: 1.4em;
  height: 1.4em;
}


.embeddedServiceSidebarButton:focus {
  text-decoration: none;
  text-decoration-color: none;
}


.embedded-service .channelmenu_ctas>li:first-child:not(.chatDisabled)+li,
.embedded-service .channelmenu_ctas>li:not(.chatDisabled):first-child>li,
.embedded-service .channelmenu_ctas>li[class~="chatDisabled"] {
  display: none !important;
}

.embeddedServiceLiveAgentStateChatAvatar.isLightningOutContext .agentIconColor1 {
  background-image: url('https://ovsspaclienti.file.force.com/servlet/servlet.ImageServer?id=0155p000008KzyY&amp;oid=00D0N000000jojk&amp;lastMod=1684319811000');

}

.embeddedServiceLiveAgentStateChatItem .isLightningOutContext .avatar.iconAvatar {
  color: transparent;
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent a {
  color: #0C1C3E;
}

.queuePositionMessage p::first-line {
  font-weight: bold;
}

.sidebarHeader.minimizedContainer.alert {
  padding: 0;
}

.embedded-service .fab div[class="icon_fab_container"]>img.esw-custom_icon {
  width: 2.5rem !important;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #0C1C3E;
  font-family: "HelveticaNeue", sans-serif;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 0.1rem solid #0C1C3E;
}

.embeddedServiceSidebar.sidebarMaximized.layout-float .minimizedContainer {
  width: 5.7rem;
}

.embedded-service .channelmenu {
  bottom: 8.5rem !important;
}

.embedded-service .fab {
  bottom: 2.5rem !important;


}


.embedded-service .fab,
.embedded-service .channelmenu,
.embeddedServiceSidebar.layout-float .dockableContainer .embeddedServiceSidebarMinimizedFabDefaultUI,
.embeddedServiceSidebar.layout-float .dockableContainer.showDockableContainer,
.embeddedServiceSidebarMinimizedFabDefaultUI {
  left: 3rem !important;
}

.embeddedServiceSidebarMinimizedFabDefaultUI .content {
  left: 0 !important;
}

.embeddedServiceSidebarMinimizedFabDefaultUI button.dismiss-button {
  color: white !important;
}

#esw-fab {
  z-index: 4;
}

@media (max-width: 768px) {

  .embedded-service .fab,
  .embeddedServiceSidebarMinimizedFabDefaultUI,
  .embeddedServiceSidebar.sidebarMaximized.layout-float .minimizedContainer {
    bottom: 11.8rem !important;
  }

  .embeddedServiceSidebar.layout-float .dockableContainer.showDockableContainer {
    left: 0 !important;
    position: fixed;
    bottom: 16.8rem;
  }

  .dockableContainer.showDockableContainer {
    max-height: 47.5rem;
  }

  .embeddedServiceSidebar.sidebarMaximized.layout-float .minimizedContainer {
    display: block !important;
  }

  div#esw-channelmenu {
    bottom: 17.5rem !important;
  }

  .embedded-service>#esw-modaloverlay.isMaximized+div#esw-container>div.channelmenu,
  .embedded-service>#esw-modaloverlay.isMaximized+div#esw-container>button.fab,
  .embedded-service > #esw-modaloverlay.isMaximized {
    z-index: 4 !important;
  }

  #esw-fab {
    z-index: 4 !important;
  }
}

#esw-channelmenu {
  z-index: 10;
}