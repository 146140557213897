[data-whatinput='mouse'] {
  .a11y-action-bar {
    display: none;
  }
}

.a11y-action-bar {
  background-color: $black;
  min-height: 2.4rem;
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  @extend .visible-on-focus;
  @extend %primary-xxs-u;

  a,
  a:link,
  a:visited {
    color: $white;
    @include link-focus-underline;
    * {
      color: $white !important;
    }
  }

  &:focus {
    outline: none;
  }
}

.wrapper-submenu {
  .a11y-action-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    justify-content: center;
  }
}

.minibag {
  .a11y-action-bar {
    position: relative;
    top: $space-unit * -6;
    left: 0;
    right: 0;
    margin-left: $space-unit * -8;
    margin-right: $space-unit * -8;
    padding-left: $space-unit * 8;
  }
}