.icon--close-16-white:hover:after,
.icon--close-16:hover:after,
.modal__close:hover:before,
.modal__close:hover:after {
  transform: rotate(90deg);
  transition: transform 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}

.icon--close-16-white:after,
.icon--close-16:after,
.modal__close:before,
.modal__close:after {
  transition: transform 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}

$elements: 10;

@for $i from 1 to $elements {
  .button--editorial li:nth-child(#{$i}) {
    transform: translateY((1.6rem + (($i * 48))));
    transition: transform 0.6s cubic-bezier(0.27, 0, 0.59, 0.99);
    z-index: $i;
  }
}