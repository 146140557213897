.product-tile {
  &__card {
    .collection-in-tile {
      display: block;
      position: absolute;
      top: ($space-unit * 4);
      left: ($space-unit * 4);
      @include z;

      span[class^='icon--'] {
        height: 3rem;
        width: 2rem;
      }

      &:focus {
        outline-color: $white;
        outline-offset: 0.2rem;
      }
    }
  }

  &:hover {
    .tile-swiper-icon {
      opacity: 1;

      &.swiper-button-disabled {
        opacity: 0.5;
      }
    }

    .variation-color-plp {
      // display: flex;
      opacity: 1;
      visibility: visible;
      height: 2rem;
    }

    .cta-show-colors {
      display: none;
    }

    .cta-quick-add-to-bag {
      opacity: 1;
      z-index: 2;
      width: 4rem;
      height: 4rem;
      background-color: white;
      margin: auto 0.8rem 0.8rem auto;
      border-radius: 100%;
    }
  }

  &__info {
    padding: ($space-unit * 4) ($space-unit * 4) ($space-unit * 8);
  }

  &__quick-buy {
    .button {
      height: ($space-unit * 6);
      background-color: transparent;
    }
  }
}