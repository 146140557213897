.accordion-component {
  margin-bottom: 6rem;

  .accordion__title {
    font-size: 2.4rem;
  }

  .accordion__header > span {
    text-transform: none;
    font-size: 1.6rem;
    font-weight: 400;
  }
}
