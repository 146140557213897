body#login-show {
  main {
    min-height: calc(var(--viewport-height) - 39.6rem);
  }
}

.login-registration {
  max-width: 31vw;
  width: 100%;
  margin: 0 auto;
  padding: 4rem 0 8rem;

  &__container {
    text-align: center;
  }

  &__tabs {
    display: grid;
    grid-template-columns: 1fr 0.1rem 1fr;

    &__title {
      padding: 2.6rem 0;
      $color: $black;
      border-bottom: 0.1rem solid $light_grey;
      font-weight: 500;
      cursor: pointer;

      &.active {
        border-bottom: 0.2rem solid $blue;
      }
    }
  }

  &__section {
    &__header {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      padding-bottom: 3.2rem;
    }

    &__btn {
      .button.button--primary {
        span {
          font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
        }
      }
    }

    &__formfields {
      .form-control {
        margin-bottom: 1.6rem;
      }

      .forgot-pw-error {
    
        &__error {
          .invalid-feedback {
            text-align: right;
            margin-top: 0;
            margin-bottom: $space-unit * 3;
          }
        }
      }
    }

    &__login {
      &__social {
        &__btn {
          background-color: $light_grey;
          border-radius: 0.4rem;
          font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
          display: flex;
          gap: 0.8rem;

          &-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.6rem;
          }
        }
      }
    }

    &__registration {
      &__privacy {
        color: $grey_registration;
        line-height: 2rem;
        a {
          color: $grey_registration;
          line-height: 2rem;          
        }
      }

      &__list {
        list-style-type: none;
        padding: 2.4rem 4.5rem;
        text-align: left;
        background-color: $grey_disabled;
        color: $blue;

        &__element {
          display: flex;
          gap: 1rem;
          padding: 0;
          
          &:not(:last-child) {
            padding-bottom: 0.8rem;
          }
        }
      }
    }
  }

  .invalid-feedback {
    text-align: left;
  }

  .divider {
    margin: 2.4rem 0;
    height: 0.1rem;
    background-color: $grey_line;
  }

  .vertical-divider {
    width: 0.1rem;
    height: 2rem;
    margin: auto 0;
    background: $grey_line;
  }

}

.login-section-container {
  padding-top: 15rem;
}