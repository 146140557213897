#minibag-container {
  .minibag {
    width: 100%;
    top: 0;
    z-index: 2;
  }
}

.feedbackbag-wrapper {
  &[data-visible='true'] {
    #feedbackbag-container {
      .overlay-feedbackbag {

        transform: translateY(-9rem);

        &.feedbackbag-translate {

          transform: translateY(0);
        }
      }
    }
  }
}