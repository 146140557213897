[data-whatintent='touch'] {
  header {
    .wrapper-submenu {
      transition: none;
    }
  }
}

header {
  // .menu-wrapper {
  // gap: 2.4rem;
  //   height: inherit;
  //   &:focus-within {
  //     .menu-item:not(:focus-within) {
  //       .menu-heading {
  //         color: $black_secondary;
  //       }
  //     }
  //   }

  // &.expanded {
  //   .menu-item:not(:hover) {
  //     .menu-heading {
  //       color: $black_secondary;
  //     }
  //   }
  // }
  // }
  .menu-heading {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }

  .menu-item {
    white-space: nowrap;

    // height: inherit;
    &.open {

      // border-bottom: 0.2rem solid $red;
      >.menu-heading::after {
        content: '';
        position: absolute;
        height: 0.2rem;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: $blue;
      }

      .wrapper-submenu {
        opacity: 1;
        visibility: visible;

        transform: translateY(0);
        pointer-events: all;
        // & > * {
        //   opacity: 1;
        // }
      }
    }
  }

  // .main-submenu {
  //   column-count: 3;
  // }

  .visually-hidden-d {
    @include visually-hidden;
  }

  .wrapper-submenu {
    border-radius: 0;
    top: 100%;

    transform: translateY(-3.2rem);
    pointer-events: none;
  }

  .menu-footer {
    border-radius: 0;

    transform: translateY(-3.2rem);
    top: -3.2rem;
    position: absolute;
    width: 100%;
    left: 0;
    top: 47.6rem;

    &.open {
      opacity: 1;
      visibility: visible;

      transform: translateY(0);
      pointer-events: all;
    }

    & .header--promo {
      padding: 1.2rem 4rem;

      & .promo-title {
        color: $light_orange;
        text-transform: uppercase;

      }

      & .promo-description {
        color: $light_orange;
        text-transform: uppercase;
      }

      & .header-promo-code {
        color: $white;
        border: 0.2rem solid $white;
        margin-left: auto;
        cursor: pointer;

        & .CTA {
          margin: auto;
          margin-right: 0.8rem;
          padding: 1.2rem 3.2rem;
          text-transform: uppercase;

          &::before {
            vertical-align: sub;
          }
        }
      }
    }

  }

  .submenu-group-empty {
    margin-top: 4rem;
  }

  .nav-wrapper {
    position: relative;
    background-color: $white;
    // @include animate(0.3);
    top: 0;

    transition: all 0.4s;

    >nav {
      padding: 0 1.2rem;
      transition: max-width 0.3s ease-in-out;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &.expanded {
      box-shadow: initial;

      // border-bottom: 0.1rem solid $grey;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0.1rem;
        background-color: $grey;
      }
    }

    &.collapsing {
      pointer-events: none;
    }

    &.collapse {
      .menu-item {
        display: none;
      }

      #mainnav {
        padding: 0;
        max-width: 5.6rem !important;
      }

      .menu-icon.hamburger-menu {
        padding: 2rem;
      }

      .icon--menu {
        // width: 5.6rem;
        // height: 5.6rem;
        // padding: 2rem;
        @include fixedSprite('menu');
        display: flex;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
  }
}