.whishlist-selectsize.modal {
  max-height: 75vh;
  width: 100%;

  .modal__body {
    padding: 0;
  }
}

.wishlist__action-line {
  right: 0;
  
  p {
    display: none;
  }
}