// https://www.w3.org/TR/wai-aria-practices-1.1/examples/radio/radio-1/radio-1.html

.radiobox {
  display: flex;
  flex: 1 1 auto;
  padding: 0.4rem;
  border-radius: 0.4rem;
  border: solid 0.1rem #ddd;
  background-color: $white;

  &__option {
    @include animate;
    height: 4.8rem;
    padding: 1.6rem;
    text-align: center;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    line-height: 1.6rem;

    text-transform: uppercase;
    color: #232323;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__option--no-outline {
    @include animate;
    padding: 0 0 $space-unit * 4;
    border: none;
  }

  [role='radio'] {
    // @include col;
    cursor: pointer;
    @extend %primary-s;

    &[aria-checked='true'] {
      .radiobox__option {
        /*
        background-color: #232323;
        color: $white;
        */
        border: 0.1rem solid $black;
      }

      .radiobox__option--no-outline {
        color: $black;
      }
    }

    &:focus {
      outline: none;

      .radiobox__option {
        @include focus-underline;
      }
    }
  }

  &--horizontal-two-items {
    [role='radio'] {
      display: flex;
      align-items: center;
      justify-content: center;
      @include col-width;
    }
  }

  &--horizontal-more-items {
    [role='radio'] {
      @include col-width;
    }
  }

  &--vertical {
    [role='radio'] {
      @include col-width(6);
    }
  }
}

.is-invalid {
  .radiobox {
    color: $status_error;
  }
}