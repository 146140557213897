// grid settings
// prettier-ignore
@use "sass:math";

$grid-columns: 12;

$grid-breakpoints: (
  xxs: 0,
  xs: 320px,
  // 2 columns, gutter 16
  sm: 768px,
  // 12 columns, gutter 24
  md: 1024px,
  // 12 columns, gutter 40
  lg: 1280px,
  // 12 columns, gutter 48
  xl: 1920px,
  // 12 columns, gutter
);

// $grid-gutter-width: 4.8rem;
$grid-gutter: (
  xs: 2.4rem,
  sm: 2.4rem,
  md: 2.4rem,
  lg: 4rem,
  xl: 4rem,
);

// $grid-metrics: (
//   gutter: (
//     xs: 1.6rem,
//     sm: 2.4rem,
//     md: 4rem,
//     lg: 4.8rem,
//     xl: 4.8rem
//   )
// )

$base-remxs: 1.6rem;
$half-remxs: math.div($base-remxs, 2);
$base-remlg: 2rem;
$half-remlg: math.div($base-remlg, 2);
$pading-xs: 2.4rem;
$pading-lg: 4rem;

$grid-metrics: (
  xs: (
    gutter: $base-remxs,
    half-gutter: $half-remxs,
    container-padding: $pading-xs,
    column-width: calc((100% / #{$grid-columns}) - $pading-xs),
    color: rgba(0, 0, 255, 0.2),
    overlay-repeatingwidth: calc((100%) / #{$grid-columns}),
    overlay-columnwidth: calc(((100%) / #{$grid-columns}) - $pading-xs),
  ),
  sm: (
    gutter: $base-remxs,
    half-gutter: $half-remxs,
    container-padding: $pading-xs,
    column-width: calc((100% / #{$grid-columns}) - $pading-xs),
    color: rgba(241, 126, 126, 0.2),
    overlay-repeatingwidth: calc((100%) / #{$grid-columns}),
    overlay-columnwidth: calc(((100%) / #{$grid-columns}) - $pading-xs),
  ),
  md: (
    gutter: $base-remlg,
    half-gutter: $half-remlg,
    container-padding: $pading-lg,
    column-width: calc((100% / #{$grid-columns}) - $pading-lg),
    color: rgba(130, 241, 126, 0.2),
    overlay-repeatingwidth: calc((100%) / #{$grid-columns}),
    overlay-columnwidth: calc(((100%) / #{$grid-columns}) - $pading-lg),
  ),
  lg: (
    gutter: $base-remlg,
    half-gutter: $half-remlg,
    container-padding: $pading-lg,
    column-width: calc((100% / #{$grid-columns}) - $pading-lg),
    color: rgba(218, 126, 241, 0.2),
    overlay-repeatingwidth: calc((100%) / #{$grid-columns}),
    overlay-columnwidth: calc(((100%) / #{$grid-columns}) - $pading-lg),
  ),
  xl: (
    gutter: $base-remlg,
    half-gutter: $half-remlg,
    container-padding: $pading-lg,
    column-width: calc((100% / #{$grid-columns}) - $pading-lg),
    color: rgba(203, 241, 126, 0.2),
    overlay-repeatingwidth: calc((100%) / #{$grid-columns}),
    overlay-columnwidth: calc(((100%) / #{$grid-columns}) - $pading-lg),
  ),
);

$grid-overlay-base-color: rgba(2, 187, 156, 0.12);
$product-ratio: 133%;
$product-ratio-num: math.div(4, 3);
