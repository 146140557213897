#account-registerphone #maincontent,
#account-registercomplete #maincontent,
#account-otp #maincontent,
#account-otpphone #maincontent,
#account-thankyouregister #maincontent,
#account-registeremail #maincontent,
#account-passwordreset #maincontent,
#account-changepassword #maincontent {
  background-color: $light_grey;
  min-height: calc(var(--viewport-height) - 8.8rem - 8.8rem);

  .login-registration {
    padding-top: 2.8rem;
  }

  .button[class*=primary] span {
    font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
    font-weight: 500;
  }
}

.register {

  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3.4rem 0;    
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.4rem 0;
    background-color: $white;
    position: relative;    
  }

  &-back {
    padding-left: 4.8rem;
    padding-top: 4rem;

    &__link {
      line-height: 1.4rem;
      text-decoration: none;  
      font-weight: 500;  
      width: fit-content;  
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;
    position: relative;

    &[data-step='3'] {
      .steps-line-blue {
        max-width: 100%;
      }
    }  
    .steps {
      display: flex;
      justify-content: space-between;
  
      .radio {
        display: flex;
        flex-direction: column;
        margin: 0;
  
        .step-label {
          &:not(.selected) {
            color: $dark_grey;
          }
  
          &.selected {
            color: $blue;
          }
        }
  
        .radio-wrapper {
          background-color: $light_grey;
          z-index: 1;
          width: fit-content;
        }
  
        &.step-1 {
          align-items: flex-start;
  
          .radio-wrapper {
            padding: 0 1.2rem 0 0;
          }
        }
  
        &.step-2 {
          align-items: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
  
          .radio-wrapper {
            padding: 0 1.2rem;
          }
        }
  
        &.step-3 {
          align-items: flex-end;
  
          .radio-wrapper {
            padding: 0 0 0 1.2rem;
          }
        }
  
        input[type='radio'] {
          margin: 0.8rem 0 0 0;
          cursor: default;
          background-color: $light_grey;;
  
          &:disabled {
            opacity: 1;
          }
  
          &:not(:checked) {
            border-color: $medium_grey;
          }
        }
      }
    }
  
    .steps-line {
      background: $medium_grey;
      height: 0.2rem;
      bottom: .9rem;
      position: absolute;
      width: 100%;
    }
  
    .steps-line-blue {
      background: $blue;
      height: 0.2rem;
      bottom: 0.9rem;
      position: absolute;
      width: 100%;
      max-width: 50%;
      transition: max-width .4s cubic-bezier(0.6, 0, 0.2, 1);
  
      &.line-hide {
        max-width: 0%;
      }
    }
  }

  &_form {
    text-align: center;
    margin-top: 8rem;

    &__title {
      padding-bottom: 0.8rem;
      color: $black;
    }

    &__subtitle {
      line-height: 2rem;
      color: $dark_grey;
    }

    &__data {
      padding: 2.4rem 0;
      color: $black;
    }

    &__disclaimer {
      color: $grey7;
    }

    &__info {
      &__text {
        text-align: left;
        color: $grey7;
      }
      
      &__gender {
        .radio-wrapper {
          position: relative;

          &:has(.invalid-feedback) {
            padding-bottom: 2.4rem;
          }

          .form-group {
            position: unset;

            .invalid-feedback {
              position: absolute;
              left: 0;
            }   
            
            .radio input {
              align-self: baseline;
            }
          }          
        }
      }
    }

    &__legal {
      background-color: $white;
      border: 0.1rem solid $medium_grey;
      border-radius: 0.4rem;
      padding: 2.4rem;
      text-align: left;
      .toggle-switch {
        justify-content: start;

        &-label {
          font-family: "HelveticaNeue", Helvetica, Arial, sans-serif;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.6rem;
          a {
            font-family: "HelveticaNeue", Helvetica, Arial, sans-serif;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.6rem;
            color: $dark_grey;
          }
        }
      }
      .newsletter-flag-second {
        &.disabled {
          pointer-events: none;
        }          
      }
    }
  
    .phone-form {
      &.row div[class*="col-"]:not([class*="col-12"]):not(:last-child)  {
        padding-right: .8rem;
      }
    }
  
    .social-row {
      margin-left: 0.1rem;
      margin-bottom: ($space-unit * 6);
  
      .social-button {
        height: ($space-unit * 4);
  
        .icon {
          height: 1.4rem;
          width: 1.4rem;
          margin-right: ($space-unit * 2);
        }
  
        .google {
          @include fixedSprite('social-google');
        }
  
        .facebook {
          @include fixedSprite('social-facebook');
          margin-left: ($space-unit * 6);
        }
      }
    }
  
    .check-pwd-regex {
      padding: 0;
      justify-content: space-between;
      flex-wrap: wrap;

      &__element {
        font-weight: 300;
        color: $black;
        padding: 0;
        font-family: "HelveticaNeue-Light", Helvetica, Arial, sans-serif;
      }
    }
  
    .otpresend {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      &__btn {
        background-color: transparent;
      }
    }
  }

}

input[type="date"]::-webkit-calendar-picker-indicator {
  @include fixedSprite('calendar');
  cursor: pointer;

  transition: all .4s cubic-bezier(0.25, 1, 0.5, 1);
}

input:not(:placeholder-shown):not([type="radio"]):is(:focus):is([type="date"])::-webkit-calendar-picker-indicator {

  transform: translateY(-0.9rem);
}

.mini-form .row {
  justify-content: center;
}