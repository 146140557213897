.checkbox {
  break-inside: avoid-column;
  position: relative;

  input[type='checkbox'] {
    position: relative;
    color: $blue;
    width: $checkbox-size;
    height: $checkbox-size;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: $white;
    border: $space-line solid $blue;
    margin: 0.2rem 0 0 0;
    border-radius: 0;
    cursor: pointer;
    flex-shrink: 0;

    &:disabled {
      opacity: 0.3;

      +label {
        opacity: 0.3;
      }
    }

    &:not(#nlFieldprivacy,#nlFieldMarketing,#nlFieldprivacy-footer)+label {
      display: inline-block;
      cursor: pointer;
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: normal;
      left: initial;
      top: initial;
      vertical-align: bottom;
      position: relative;
      padding-left: ($space-unit * 2);
    }

    &#nlFieldprivacy,
    &#nlFieldprivacy-footer,
    &#nlFieldMarketing {
      &+label {
        cursor: pointer;
        font-weight: normal;
        left: initial;
        top: initial;
        vertical-align: bottom;
        position: relative;
        padding-left: 1.2rem;        
      }
    }

    &:checked {
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;

        transform: translate(-50%, -50%);
        @include fixedSprite('checkbox-active');
        opacity: 1;
        transition: opacity 0.4s cubic-bezier(0.6, 0, 0.2, 1);
      }
    }

    &:not(:checked) {
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;

        transform: translate(-50%, -50%);
        @include fixedSprite('checkbox-active');
        transition: opacity 0.4s cubic-bezier(0.6, 0, 0.2, 1);
        opacity: 0;
      }
    }
  }

  // container
  .checkbox__field {
    display: flex;
    align-items: flex-start;

    // margin-bottom: 1rem;
  }
}

.is-invalid {
  .checkbox {
    color: $status_error;
  }
}

.toggle-switch {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 1.6rem;
  font-size: 1.4rem;

  cursor: pointer;

  &>div {
    margin-left: 1.6rem;
  }
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}

.toggle {
  position: relative;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $medium_grey;

  transition: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);

  &:disabled {
    &:before {
      background-color: $light_grey;
    }
  }
}

.toggle:before {
  position: absolute;
  content: '';
  height: 1.2rem;
  width: 1.2rem;
  left: 0.2rem;
  bottom: 0.2rem;
  background-color: $white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input[type='checkbox']:disabled+.toggle:before {
  background-color: $light_grey;
}

input[type='checkbox'][disabled]:checked+.toggle {
  background-color: $medium_grey;
}

input[type='checkbox'][disabled]:checked+.toggle:before {
  background-color: $light_grey;
}

input[type='checkbox']:checked+.toggle {
  background-color: $blue;
}

input[type='checkbox']:checked+.toggle:before {

  transform: translateX(1.2rem);
}

.toggle.round {
  border-radius: 3.4rem;
  width: 2.8rem;
  height: 1.6rem;
  min-width: 2.8rem;
  max-width: 2.8rem;
}

.toggle.round:before {
  border-radius: 50%;
}