[data-component='BenefitBarComponent'] {
  .benefitBar{
    &-title{
      &-item {
        left: 1.6rem;
        .title{
          font-size: 1.2rem;
          line-height: 1.8rem;
        }
      }
    }

    &-body{
      overflow: auto;
      height: 100dvh;
      
      &-group{
        gap: 3.2rem;
        padding: 4.8rem 1.6rem;
        flex-direction: column;

        &-child{
          position: relative;
        }
  
        .title{
          font-size: 1.2rem;
          line-height: 1.6rem;
          margin-bottom: 0.8rem;
        }
        .subtitle{
          font-size: 1.2rem;
          line-height: 1.6rem;
          margin-bottom: 0.8rem;
        }
        .link{
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
    }

    &-image{
      &-wrapper{
        flex-direction: column;
      }
      
      &-child{
        .image{
          padding: 3.2rem 21rem 3.2rem 2.4rem;
          min-height: 18rem;
          background-size: cover;
          background-position: right;
        }

        .title{
          font-size: 1.6rem;
          line-height: 2.4rem;
        }

        .cta{
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
    }
  }

  .icon--close-white {
    position: absolute;
    top: 2.6rem;
    right: 2.6rem;
    cursor: pointer;
  }

  .editmode & {
    .benefitBar{
      &-title {
        display: none;
      }
      
      &-body{
        position: relative;
        display: block;
      }
    }
  }
}