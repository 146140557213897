.heading-block {
  margin: 2rem 0;

  .title {
    font-size: 3.6rem;
  }
  .subtitle {
    font-size: 1.6rem;
  }
}
