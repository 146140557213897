#maincontent,
#checkout-main,
.sizeGuideModal {
  .accordion__group {
    text-align: left;
    position: relative;

    .accordion__item {
      &:last-child {
        @include border('bottom');

        .accordion__header {
          border-bottom: initial;
        }
      }
    }
  }

  .my_accordion__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &::after {
      content: '';
      @include fixedSprite('icon-accordion-dw');

      transition: transform 0.3s ease-in-out;
    }

    &[aria-expanded='false'] {
      &::after {
        transform: rotate(90deg);
      }
    }

    &[aria-expanded='true'] {
      &::after {
        transform: rotate(270deg);
      }
    }
  }

  .my_accordion__panel {
    overflow: hidden;
    visibility: visible;

    transition: all 0.3s ease-in-out;
  }

  .accordion__header:not(.filter-tab .accordion__header) {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;

    width: 100%;
    font-size: 1.4rem;

    font-weight: bold;
    
    &:not(.sizeGuideAccordion__header){
      color: $black;
      padding: 2.4rem 0;      
    }
  }

  .accordion__header {
    &>span {
      text-align: left;
    }

    &:focus {
      outline: transparent;

      .header__open,
      .header__close {
        [data-whatintent='keyboard'] & {
          outline: $outline-width solid $outline-color;
        }
      }
    }

    &--uc {
      &>span {
        @extend %primary-xs-u;
      }
    }

    &--lc {
      &>span {
        @extend %primary-s;
      }
    }

    .header__open,
    .header__close {
      position: absolute;
      right: -0.8rem;
      top: 50%;

      transform: translateY(-50%);
      min-width: ($space-unit * 6);
      min-height: ($space-unit * 6);
      width: auto;
      height: auto;
      display: none;
      align-items: center;

      .text {
        @extend %primary-xs;
        display: none;
      }

      &::after {
        content: '';
        display: block;
        width: ($space-unit * 6);
        height: ($space-unit * 6);
      }
    }

    &--cta-text {
      &>span {
        width: calc(100% - 5.4rem);
      }

      .header__open,
      .header__close {
        .text {
          display: block;
        }

        .text,
        &::after {
          opacity: 0.55;
        }
      }
    }

    &::after {
      content: '';
      @include fixedSprite('icon-accordion-dw');

      transition: transform 0.3s ease-in-out;
    }

    &[aria-expanded='false'] {
      .header__open {
        display: flex;
      }

      &::after {
        transform: rotate(90deg);
      }

      &+.accordion__panel {
        max-height: 0 !important;
        border-bottom: 0.1rem solid $medium_grey;
      }
    }

    .product-info__item--content:before {
      width: 1.8rem;
    }

    &[aria-expanded='true'] {
      &::after {
        transform: rotate(270deg);
      }

      .header__close {
        display: flex;
      }

      &+.accordion__panel:not(.sizeGuideAccordion__panel) {
        border-bottom: 0.1rem solid $dark_grey;
      }
    }

    &.filter-horizontal {
      &::after {
        content: '';
        @include fixedSprite('chevron-up-blue');

        transition: transform 0.3s ease-in-out;
      }

      &[aria-expanded='false'] {
        &::after {
          transform: rotate(-180deg);
          filter: saturate(100) grayscale(1);
        }

        &+.accordion__panel {
          border-bottom: 0 !important;
        }
      }

      &[aria-expanded='true'] {
        &::after {
          transform: rotate(0);
        }
      }
    }

    &--payments {
      align-items: flex-start;

      .header__open,
      .header__close {
        top: 1rem;
        transform: initial;
      }

      >span {
        white-space: initial;
        line-height: 1.6;
      }
    }
  }

  .refinements-horizontal .filters-container {
    .accordion__panel {
      border-right: 0.1rem solid $light_grey;
      border-bottom: 0.1rem solid $light_grey;
    }
  }

  .refinement-sustainibility {
    border-bottom: 0.1rem solid $medium_grey;
  }

  .accordion__panel {
    overflow: hidden;
    visibility: visible;

    transition: all 0.3s ease-in-out;

    >* {
      padding-top: $space-unit;
    }

    > :last-child:not(.sizeGuideTable__wrapper, .sizeGuideMeasureModal) {
      padding-bottom: 3rem;
    }

    &--grenoble {
      text-transform: uppercase;
    }
  }

  .filter-horizontal+.accordion__panel {
    >* {
      padding-top: 4rem;
    }
  }

  .modal-isml:not(#filters-modal) .accordion__panel>ul>li:is(:last-child) {
    padding-bottom: 2rem;
  }
}