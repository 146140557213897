body.app {
  #DW-SFToolkit {
    display: none; // old toolkit, just use the new one
  }

  main {
    min-height: 81rem;
  }

  .noapp {
    display: none !important;
  }

  .noapplink {
    text-decoration: none;
  }

  main {
    background-color: $white;
    background-image: none;
  }

  .checkout-container {
    .checkout-login {
      margin-top: $space-unit * 4;
    }

    #customerStep {
      .step__header {
        border-top: none;
      }
    }

    .autocomplete {
      width: calc(100% - 3.2rem);
      min-width: calc(100% - 3.2rem);
      max-width: 34.3rem;

      .attribution {
        display: none;
      }

      li {
        background-color: $input_background;
        border: 0.1rem solid $white;
        text-align: left;
      }
    }

    #packagingStep {
      .step__panel {
        min-height: 73.1rem;

        .radiobox__option {
          min-height: 25.8rem;

          img {
            width: 14rem;
            height: 10.2rem;
          }
        }
      }
    }

    #shippingStep {
      .step__panel {
        min-height: 50rem;
      }

      .shippingstepdetail {
        min-height: 40rem;

        .address_form {
          min-height: 60rem;
        }
      }
    }

    #paymentStep {
      .step__panel {
        min-height: 32.4rem; // minimo con 2 metodi di pagamento abilitati e no form carta
      }
    }
  }

  .loader.full {
    background-color: rgba(255, 255, 255, 1);
    flex-direction: column;

    @keyframes ldio-hn0fflew9qp {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    .ldio-hn0fflew9qp div {
      left: 49.5rem;
      top: 4.2rem;
      position: absolute;
      animation: ldio-hn0fflew9qp linear 1s infinite;
      background: #000000;
      width: 0.1rem;
      height: 0.4rem;
      border-radius: 0;
      transform-origin: 0.5rem 0.8rem;
    }

    .ldio-hn0fflew9qp div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -0.875s;
      background: #000000;
    }

    .ldio-hn0fflew9qp div:nth-child(2) {
      transform: rotate(45deg);
      animation-delay: -0.75s;
      background: #000000;
    }

    .ldio-hn0fflew9qp div:nth-child(3) {
      transform: rotate(90deg);
      animation-delay: -0.625s;
      background: #000000;
    }

    .ldio-hn0fflew9qp div:nth-child(4) {
      transform: rotate(135deg);
      animation-delay: -0.5s;
      background: #000000;
    }

    .ldio-hn0fflew9qp div:nth-child(5) {
      transform: rotate(180deg);
      animation-delay: -0.375s;
      background: #000000;
    }

    .ldio-hn0fflew9qp div:nth-child(6) {
      transform: rotate(225deg);
      animation-delay: -0.25s;
      background: #000000;
    }

    .ldio-hn0fflew9qp div:nth-child(7) {
      transform: rotate(270deg);
      animation-delay: -0.125s;
      background: #000000;
    }

    .ldio-hn0fflew9qp div:nth-child(8) {
      transform: rotate(315deg);
      animation-delay: 0s;
      background: #000000;
    }

    .loadingio-spinner-spinner-a3as68esyai {
      width: 10rem;
      height: 10rem;
      display: inline-block;
      overflow: hidden;
      background: none;
    }

    .ldio-hn0fflew9qp {
      width: 10rem;
      height: 10rem;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0;
    }

    .ldio-hn0fflew9qp div {
      box-sizing: content-box;
    }

    .loadingmessage {
      @extend %primary-s;
      text-align: center;
    }
  }
}