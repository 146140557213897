[data-component='BenefitBarComponent'] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @include z(benefit-bar);
  background-color: $blue;
  display: flex;
  height: fit-content;
  transition: all 250ms ease-out;
  overflow: hidden;

  p {
    color: white;
  }

  .benefitBar{
    &-title, &-body{
      width: 100%;
      position: relative;
      z-index: 1;
      padding: 0;
      list-style: none;
    }

    &-title{
      height: 3.2rem;
  
      &-item {
        position: absolute;
        top: 50%;
        left: 4rem;
        transform: translateY(-50%);
        width: fit-content;
        margin: 0;
        color: $white;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.2;
        opacity: 0;
        z-index: -1;
        transition: all 0.3s;
        pointer-events: none;

        .subtitle, .cta{
          display: none;
        }

        .title{
          font-size: 1.4rem;
          line-height: 2rem;
          font-weight: 400;
          &::after{
            content: '';
            @include fixedSprite('chevron-down-white');
          }
        }
  
        &.active {
          opacity: 1;
          z-index: 1;
          transition: all 0.3s;
          pointer-events: initial;
        }
      }
    }

    &-body{
      position: fixed;
      top: 0;
      width: 100vw;
      color: white;
      overflow: hidden;
      display: none;
  
      &-group{
        display: flex;
        gap: 8rem;
        background: $blue;
        padding: 4.8rem 13rem 3.2rem;

        &-child{
          flex: 1 0 0;
        }
  
        .title{
          font-size: 1.4rem;
          line-height: 2rem;
          margin-bottom: 1.6rem;
        }
        .subtitle{
          font-size: 1.2rem;
          line-height: 1.6rem;
          margin-bottom: 3.2rem;
        }
        .link{
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
  
      .js-close-benefit {
        position: absolute;
      }
  
      &.open+.overlay {
        opacity: 1;
        visibility: visible;
      }
  
      &+.overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 150vh;
        background: rgba(0, 0, 0, .3);
        @include z(neg);
        @include animate(0.3);
        opacity: 0;
        visibility: hidden;
      }
    }

    &-image{
      &-wrapper{
        width: 100vw;
        display: flex;
      }
      
      &-child{
        flex: 1 0 0;

        .image{
          padding: 6.4rem 31rem 6.4rem 4.8rem;
          min-height: 40rem;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }

  .icon--close-white {
    position: absolute;
    top: 2.6rem;
    right: 2.6rem;
    cursor: pointer;
  }

  .editmode & {
    .benefitBar{
      &-title {
        display: none;
      }
      
      &-body{
        position: relative;
        display: block;
      }
    }
  }
}