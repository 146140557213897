.product-tile {
  &__info {
    padding-top: ($space-unit * 3);
  }

  &__quick-buy-panel,
  &__quick-buy {
    display: none;
    background-color: greenyellow !important;
  }
}

.cta--wishlist {

  &::after {

    transform: translateY(-50%);
  }
}

.product-tile:hover {
  .tile-swiper-icon {
    opacity: 0;

    &.swiper-button-disabled {
      opacity: 0.5;
    }
  }

  .variation-color-plp {
    // display: flex;
    opacity: 0;
    visibility: hidden;
    height: 2rem;
  }

  .cta-quick-add-to-bag {
    opacity: 1;
    z-index: 2;
    width: 4rem;
    height: 4rem;
    background-color: white;
    margin: auto 0.8rem 0.8rem auto;
    border-radius: 100%;
  }

  .swiper-container-horizontal {
    .cta-quick-add-to-bag {
      display: none;
    }
  }
}

.product-tile__card {

  .tile-swiper-icon,
  .wrapper-quick-add-to-bag {
    display: none;
  }
}