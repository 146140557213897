.cta {
  position: relative;

  &--plain {
    text-decoration: none;
    padding-bottom: 0.1rem;
    @include border-black(bottom);
  }

  &--edit {
    padding-left: 1.8rem;

    &::after {
      content: '';
      @include fixedSprite('edit');
      @include center('vertical');
      left: 0;
    }
  }

  &--remove {
    padding-right: 1.8rem;

    &::after {
      content: '';
      @include fixedSprite('delete');
      @include center('vertical');
      right: 0;
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &--wishlist {
    font-size: 1.2rem;
    font-weight: bold;
    padding-left: 1.8rem;

    &::after {
      content: '';
      @include fixedSprite('wish');
      @include center('vertical');
      left: 0;
      width: 1.6rem;
      height: 1.6rem;
    }

    &.full {
      &::after {
        content: '';
        @include fixedSprite('wish-full');
        @include center('vertical');
        left: 0;
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  &--wishlist-white {
    font-size: 1.2rem;
    font-weight: bold;
    padding-left: 1.8rem;

    &::after {
      content: '';
      @include fixedSprite('wish-white');
      @include center('vertical');
      left: 0;
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &--modify {
    font-size: 1.2rem;
    font-weight: bold;
    padding-left: 1.8rem;

    &::after {
      content: '';
      @include fixedSprite('edit');
      @include center('vertical');
      left: 0;
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &--saveforlater {
    padding-right: 1.8rem;

    &::after {
      content: '';
      @include fixedSprite('chevron-right');
      @include center('vertical');
      right: 0;
    }
  }

  &--sizeguide {
    font-size: 1.2rem;
    font-weight: bold;
    padding-left: 1.8rem;

    &::after {
      content: '';
      @include fixedSprite('fit');
      @include center('vertical');
      left: 0;
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}