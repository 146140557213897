.wrapper-submenu {
  .a11y-action-bar {
    display: none !important;
  }
}

[data-whatinput='touch'] {
  .a11y-action-bar {
    display: none;
  }
}
