[data-whatintent='keyboard'] {
  header {

    .wrapper-submenu,
    .menu-actions .icon--menu {
      transition: none;
    }
  }
}

header {
  position: sticky;
  top: var(--ticker-height);
  left: 0;
  width: 100%;
  // transition-property: background, transform;
  // transition-duration: 0.4s;
  // transition-timing-function: $easeOutQuart;
  // @include animate;
  @include z(header);

  [data-react='minicart-app'] {
    .tmploader {
      display: flex;
    }
  }

  .experience-component.experience-menu_item {
    display: none;
  }

  ul {
    @include reset-list;
  }

  &.checkout {
    position: relative;

    +main {
      background: $white;
      padding-top: ($space-unit * 8);
    }

    .navigation {
      h1 {
        @extend %primary-m-u;
        position: absolute;
        bottom: 1%;
        left: 50%;

        transform: translateX(-50%);
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 150vh;
    background: $overlay;
    @include z(neg);
    @include animate(0.3);
    opacity: 0;
    visibility: hidden;
  }

  .header-links-component .button {
    justify-content: flex-start;
  }

  .ticker-bar {
    position: relative;
    width: 100%;
    height: $ticker-bar;
    background-color: $black;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: height 0.2s ease-out;
    @extend %primary-xs-u;

    &.clickfromstore {
      background-color: $status_error;
    }

    .icon--close-white {
      position: absolute;
      right: $space-unit;
      top: 50%;

      transform: translateY(-50%);
    }

    &.close {
      height: 0;
    }
  }

  .header-account-link,
  .header-links-component {
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }

  .navigation {
    // height: $header-height-desktop;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include animate(0.3);
  }

  &.checkout .navigation {
    align-items: center;
  }

  [data-menu-back] {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 6rem;
    z-index: 1;
    @include animate(0.3);
    height: 6.4rem;
    margin-right: auto;
    text-align: initial;
    padding-left: 2.4rem;
    left: 0;
    background: white;
    width: 100%;
  }

  .nav-logo {
    padding: 0 4rem;
  }

  .logo-header {
    // position: absolute;
    // top: 0;
    // left: 2.4rem;
    display: block;
    outline-offset: 0.1rem;
    @include z(logo);
    @include animate(0.3);

    img {
      display: block;
      width: 5.6rem;
    }
  }

  .menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-footer {
    position: absolute;

    transform: translateX(3.2rem);
    opacity: 0;
    visibility: hidden;

    transition: all 0.3s 0s cubic-bezier(0.25, 1, 0.5, 1);
    overflow: hidden;
    background-color: $white;
    width: 100%;
    left: 0;
    top: 47.6rem;
  }

  .menu-heading,
  .submenu-heading {

    &,
    a,
    p {
      display: block;
      @include del-link;
    }
  }

  nav {
    sup {
      vertical-align: super;
      font-size: 0.9rem;
      display: contents;
    }
  }

  .menu-heading {
    padding: 3.4rem 1.2rem;
    text-transform: uppercase;
    color: $blue;
    // to avoid button appereance on some browsers
    appearance: initial;
    @include animate(0.3);
    @include z;

    &:focus {
      @include focus-underline;
    }

    &.featured {
      color: $red;
    }
  }

  .submenu-heading {
    margin-bottom: ($space-unit * 5);
    color: $blue;
    min-height: 2rem;
    cursor: default;

    &,
    a,
    p {
      text-transform: uppercase;
    }
  }

  .submenu-group {
    ul li {
      margin-bottom: ($space-unit * 5);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .submenu-item {
    text-decoration: none;
    color: $dark_grey;
    // @include hover(0.3);

    &.viewAll {
      color: $blue;
      border-bottom: 0.1rem solid $blue;
      padding-bottom: 0.3rem;
    }

    &:hover {
      color: $dark_blue;
    }

    &.submenu-item-empty {
      color: red;
    }
  }

  .wrapper-submenu {
    min-height: 38.8rem;
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;

    transform: translateX(3.2rem);
    opacity: 0;
    visibility: hidden;
    @include animate(0.3);
    overflow: hidden;
    background-color: $white;
  }

  .visual-submenu,
  .container-submenu {
    // display: flex;
    justify-content: center;
  }

  .container-submenu {
    padding: 4rem;
  }

  .main-submenu {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2rem;
  }

  .visual-submenu__item {
    flex: 1 0 25%;
    display: block;
    max-width: 25%;
  }

  .menu-actions {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: auto;

    [class^='icon'] {
      @include hover(0.3);
    }

    .icon--search {
      @include fixedSprite('search');
    }

    .menu-icon {

      .icon--search,
      .icon--account,
      .icon--bag,
      button.hamburger,
      .icon--wish,
      .icon--wish-full {
        padding: 2rem;
        width: 5.6rem;
        height: 5.6rem;
        background-origin: content-box;
        display: flex;
      }

      .wrapper-search .icon--close-16 {
        padding: 0 2rem;
        width: 3.2rem;
        height: 3.2rem;
        margin: 2rem;
        background-origin: content-box;
      }

      .icon--wish,
      .icon--wish-full {
        display: flex;
      }
    }

    .account-wrapper {
      .account-cta {
        text-decoration: none;
        display: flex;
        align-items: center;

        .account-cta__label {
          @extend %primary-s;
          margin-left: 0.2rem;
        }
      }

      .account-logout {
        @extend %primary-s;
        display: none;
      }
    }

    .icon--bag {
      position: relative;

      span {
        @extend %primary-xxs;
        position: absolute;
        left: 50%;
        top: 50%;

        transform: translate(-15%, -15%);
        width: 1.6rem;
        height: 1.6rem;
        background-color: $red;
        border: 0.1rem solid $white;
        border-radius: 50%;
        color: $white;
        font-size: 0.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .hamburger {
      display: flex;
      flex-direction: column;
      height: $space-unit * 4;
      width: $space-unit * 4;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &__line {
        width: 1.6rem;
        height: 0.2rem;
        background-color: $black;
        @include animate;

        &:nth-child(1) {
          transform-origin: top right;
        }

        &:nth-child(2) {
          margin: 0.4rem 0;
        }

        &:nth-child(3) {
          transform-origin: bottom right;
        }
      }

      &[aria-expanded='true'] {
        .hamburger__line {
          &:nth-child(1) {
            transform: rotate(-45deg) translate(-0.2rem, -0.18rem);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(45deg) translate(-0.22rem, 0.11rem);
          }
        }
      }
    }
  }

  .minibag-wrapper {
    display: flex;
  }

  .container-nav {
    padding: 0;
    height: 0.02rem;
  }

  .menu-heading {
    text-transform: uppercase;
  }

  .nav-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 3.2rem;
    border-bottom: 0.1rem solid $light_grey;
    background: $white;

    &.collapse-translate {

      transform: translateY(calc(-1 * var(--nav-wrapper)));
    }
  }

  .image-column {
    position: relative;

    >a {
      display: block;
      outline-offset: 0.1rem;
      height: 100%;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      img {
        height: 28rem;
        width: auto;
        object-fit: cover;
      }
    }
  }

  .image-heading {
    position: relative;
    color: $black;
    z-index: 1;
    margin: 0.8rem 0 0 0;
    font-size: 1.4rem;
    line-height: 2rem;


    &:hover {
      text-decoration: underline;
    }

  }

  .icon--menu {
    display: none;
  }
}

.widget-1-editorial a {
  text-decoration: none;
}

.widget-1-editorial .img-wrapper {
  max-width: 21rem;

  img {
    max-height: 28rem;
  }
}