.register {

  &_form {
    margin-top: 4rem;
    padding: 0 1.6rem;

    &__info {
      &__text,
      &__gender {
        padding-left: 1.6rem;
      }

      &__gender {
        .radio-wrapper {
          flex-direction: column;
          gap: 1.6rem;
        }
      }
    }  
  }

  &-header {
    padding: 1.2rem 0;
  }

  &-footer {
    text-align: center;
    padding: 2rem 1.6rem;
  }

  &-back {
    padding-left: 1.6rem;
    padding-top: 3.2rem;
  }

  &__steps {
    max-width: 83vw;
    margin: 0 auto;
  }

}

.mini-form .row {
  gap: unset;

  div[class^="col-"]:not([class*="-12"]):not(:first-child):last-child {
    padding-left: 1.6rem;
  }

  .col-8 {
    max-width: 100%;
  }

  .check-pwd-regex {
    flex-direction: column;
  }
}

#account-registerphone #maincontent,
#account-registercomplete #maincontent,
#account-otp #maincontent,
#account-otpphone #maincontent,
#account-thankyouregister #maincontent,
#account-registeremail #maincontent,
#account-passwordreset #maincontent,
#account-changepassword #maincontent {
  min-height: calc(var(--viewport-height) - 5.6rem - 8rem);
}