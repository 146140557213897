// https://www.w3.org/TR/wai-aria-practices-1.1/examples/radio/radio-1/radio-1.html

.radiobox {
  flex: 1 0 auto;

  &--horizontal-more-items {
    [role='radio'] {
      @include col-width(4);
    }
  }
}
