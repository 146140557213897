.customer-addresses {
  .tingle-modal-box {
    &.right-modal-attribute {
      width: 100%;
    }
  }
}

.modal-go-back {
  .tingle-modal-box {
    width: 88.4vw;
  }
}

.header-enhanced-login .tingle-modal-box {
  &__content {
    .modal {
      &-header {
        padding: 2.4rem 2.2rem;
      }
      &-content {
        padding: 2.4rem 2.2rem;
      }
      &-footer {
        margin-top: 4rem;
      }
    }
  }
}