.product-carousel__carousel-container,
.product-trending__carousel-container,
.complete-your-look-carousel,
.recommendations-carousel,
.infinite-carousel {

  .swiper-wrapper img:not(:first-child) {
    display: none;
  }

  .swiper-button-container {
    display: none;
  }

  .tile-body .row {
    align-items: flex-start;

    .cta--wishlist {
      top: 0.3rem;
    }
  }
}

.product-carousel__title {
  padding-left: 2.4rem;
  overflow-wrap: break-word;
}

.complete-your-look-container .complete-your-look-carousel {
  grid-template-columns: repeat(2, 1fr);
}

.product-carousel {
  padding: 0 0 0 2.4rem;
}

.product-carousel__carousel-container {
  .single-slide__labels {
    margin-bottom: $space-unit * 2;
  }
}

.product-carousel__text {
  text-align: left;
  margin-top: $space-unit * 8;
  margin-bottom: $space-unit * 6;
}

.product-trending__carousel-container,
.product-carousel__carousel-container {

  transform: translateX(2.4rem);
  overflow: visible;
  padding: 0;
  width: calc(100vw - 2.4rem);
}

.product-carousel__carousel-container .swiper-container {
  margin: 3rem 4.8rem 0 0;
}

.product-trending__carousel-container .swiper-container {
  margin: 8rem 4.8rem 0 0;
}