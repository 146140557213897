.order-tile {
  margin-bottom: 1.6rem;

  .order-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1.6rem;
  }

  .order-footer {
    p {}

    &__totals {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 0.4rem;

      .InProgress,
      .Ordered,
      .Shipped {
        color: $dark_blue;
      }

      .Canceled {
        color: $red;
      }

      .Returned {
        color: $green;
      }
    }

    &__tracking {
      align-content: center;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.order-item.returned>*,
.order-item.returned>*>* {
  color: rgb(0 0 0 / 30%);
}

.order-item.returned .order-item__image {
  opacity: 0.3;
}

.order-item.returned .order-item__content .order-item__details .returnLabel {
  color: $red;
}

.order-items {
  .order-item {
    display: flex;
    margin-bottom: 1.6rem;

    &__name {}

    &__image {
      height: auto;
      flex-basis: 11rem;


      img {
        width: 100%;
      }
    }

    &__price {
      margin-top: 0.4rem;
      margin-bottom: 0.8rem;
    }

    .order-item__content {
      padding-left: 1.6rem;
      flex: 1;
    }
  }

  .checkbox {
    margin-right: 0.8rem;
  }
}

.account-dashboard {

  .orders-listing,
  .wishlist {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .component_title {
        margin-bottom: 0;
      }
    }
  }
}

.order-items.disabled {
  opacity: 0.3;
}

.order-detail-page {
  .order-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 2.4rem;

    p {
      display: flex;
      flex-direction: column;
    }

    .label {
      font-size: 1.2rem;
      color: $dark_grey;
      margin-bottom: 0.6rem;
    }
  }

  .order-items {
    .order-item-wrapper {
      &:not(:last-child) {
        margin-bottom: 1.8rem;
        border-bottom: 0.1rem solid $light_grey;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .order-item__content {
    display: flex;
    flex-direction: column;
    position: relative;

    .order-sub-line {
      bottom: 0;
      position: absolute;
      display: flex;
    }
  }

  .order-item__details {
    margin-top: auto;
  }

  .shipping-headings {
    display: none;
  }

  .return-item-box {
    &:not(:last-of-type) {
      margin-bottom: 2.4rem;
      padding-bottom: 2.4rem;
      border-bottom: 0.1rem solid $light_grey;
    }
  }
}

.order-summary-label {
  font-size: 1.8rem;
}

#order-trackingform {
  background-color: $light_grey;

  .tracking-line {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
}