body.tingle-enabled {
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0 !important;
}

html.html-tingle-enabled {
  overflow: hidden;
}

.modal {
  .fade {
    display: none;
  }
}

.tingle-modal-box.right-modal-attribute,
.tingle-modal.right-modal-attribute .tingle-modal-box {
  right: 0;
  position: absolute;
  height: 100vh;
}

.tingle-modal {
  z-index: 1050; // 1597 TODO: Too much. Google autocomplete z-index: 1000
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  background: rgba($black, 0.65);
  cursor: default;
  transition: opacity .35s cubic-bezier(0.165, 0.84, 0.44, 1);

  &.tingle-modal--visible {
    visibility: visible;
    opacity: 1;

    .tingle-modal-box__footer {
      bottom: 0;
    }

    .tingle-modal-box {

      transform: translateY(0);
      opacity: 1;
    }
  }

  &__close,
  &__closeLabel {
    display: none;
  }

  &--noClose {
    &.tingle-modal__close {
      display: none;
    }
  }

  &.customer-addresses {
    z-index: 1000;
  }
}

.quickview-modal .tingle-modal-box {
  right: 0;
  position: absolute;
  height: 100%;
  max-height: 100%;
  width: 42rem;
  background-color: $white;

  .tingle-modal-box__content {
    height: 100%;
    position: relative;

    .quickview-header {
      padding: 2rem 2.4rem;
      border-bottom: 0.1rem solid $light_grey;

      .quickview-close {
        position: absolute;
        right: 0;
        top: 0;
        padding: 2.8rem 2.4rem;
        z-index: 3;
      }
    }

    .quickview-content {
      padding: 2.4rem;

      select {
        padding: 1.5rem;
        border-radius: 0.4rem;
        border: 0.2rem solid $grey_line;
        cursor: pointer;
        padding-right: 0.4rem;

        transition: all .4s ease;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        @include fixedSprite('chevron-down-gray');
        width: 100%;
        height: 100%;
        background-position: 96% 50%;
      }
    }

    .modal-footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      border-top: 0.1rem solid $light_grey;

      .quick-edit-buttons {
        padding: 2rem 2.4rem;

        button {
          width: 100%;
        }
      }
    }
  }
}

.cart-remove-product .tingle-modal-box {
  position: relative;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: auto;
  max-width: 50rem;
  max-height: 90vh;
  background: $white;
  cursor: auto;
  overflow-y: auto;

  transform: translateY(5rem);
  opacity: 0;

  transition: all .35s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 0.4rem;

  &__content {
    .icon--close-16 {
      position: absolute;
      top: 2.4rem;
      right: 2.4rem;
      font-size: 2em;
      cursor: pointer;
    }

    .modal-body {
      text-align: center;
      max-width: 37rem;
      margin: auto;

      >* {
        margin: 0.8rem 0;
      }
    }

    .modal-content {
      padding: 4rem 2.4rem;
    }

    .modal-footer {
      display: flex;
      gap: 2rem;
      margin: 2.4rem 0 0;

      button {
        width: 19rem;
        overflow: hidden;
      }
    }

    button {
      margin-bottom: 0;

      &.js-apply-coupon {
        margin-bottom: 4.2rem;
      }
    }

    .bottom-buffer {
      margin-bottom: 10vh;
    }
  }

  .modal__closeIcon {
    position: absolute;
    top: 1.3rem;
    right: 1.3rem;
    font-size: 2em;
    cursor: pointer;
  }

  &__footer {
    padding: 1rem 2rem;
    width: auto;
    cursor: auto;
    text-align: right;

    &:after {
      display: table;
      clear: both;
      content: "";
    }

    &--sticky {
      position: fixed;
      bottom: -20rem; // TODO: find a better way
      z-index: 10001;
      opacity: 1;
      transition: bottom .35s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    button {
      margin-bottom: 0;
    }
  }

  &.resize-modal-loyalty {
    width: 60vw;
  }
}

.header-enhanced-login .tingle-modal-box {
  position: relative;
  flex-shrink: 0;
  margin: auto;
  max-width: 44rem;
  width: 100%;
  background: $white;
  cursor: auto;
  overflow-y: auto;
  transform: translateY(5rem);
  opacity: 0;
  transition: all .35s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 0.4rem;

  &__content {
    .modal {
      &-header {
        padding: 2.4rem;
        border-bottom: 0.1rem solid $grey_disabled;
      }

      &-content {
        padding: 2.4rem;
  
        .modal-body {
          margin: auto;
  
          .login-form-container {
            .form-group {
              margin-bottom: 1.6rem;
            }
          }

          .password-reset {
            font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
          }
        }
      }

      &__social {
        &__btn {
          background-color: $light_grey;
          border-radius: 0.4rem;
          font-family: HelveticaNeue, Helvetica, Arial, sans-serif;

          &-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
        }
      }

      &-footer {
        margin-top: 4.8rem;
      }
    }
  }
}

.modal-go-back {
  .header-wrapper {
    font-size: 2rem;
    line-height: 2.8rem;
    padding-right: 3.2rem;
  }

  .modal-close {
    display: block;
    background-color: unset;
    cursor: pointer;

    &::after {
      width: 0;
      height: 0;
      padding: 0;
      position: absolute;
      top: 0;
      right: 0;
      margin: 2.4rem;
      content: '';
      @include fixedSprite('close');
    }
  }

  .tingle-modal-box {
    padding: 2.4rem;
    border-radius: 0.4rem;
    position: relative;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 30.5vw;
    max-width: 44rem;
    background: $white;
    cursor: auto;
    overflow-y: auto;
    transform: translateX(100%);
    transition: all 0.3s ease-out;

    &__content {
      .modal-body {
        margin: auto;

        >*:not(:first-child, :last-child) {
          margin: 0.8rem 0;
        }
      }

      button {
        margin-bottom: 0;
        border: 0.1rem solid $blue ;

        &::after {
          display: none;
        }

        &.js-apply-coupon {
          margin-bottom: 4.2rem;
        }
      }

      .bottom-buffer {
        margin-bottom: 10vh;
      }
    }

    .modal__closeIcon {
      position: absolute;
      top: 1.3rem;
      right: 1.3rem;
      font-size: 2em;
      cursor: pointer;
    }

    &__footer {
      padding: 1rem 2rem;
      width: auto;
      cursor: auto;
      text-align: right;

      &:after {
        display: table;
        clear: both;
        content: '';
      }

      &--sticky {
        position: fixed;
        bottom: -20rem; // TODO: find a better way
        z-index: 100010;
        opacity: 1;
        transition: all 0.3s ease-out;
      }

      button {
        margin-bottom: 0;
      }
    }

    &.resize-modal-loyalty {
      width: 60vw;
    }
  }
}

.customer-addresses {
  .tingle-modal-box {
    position: relative;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 80vw;
    max-width: 40rem;
    background: $white;
    cursor: auto;
    overflow-y: auto;
    transform: translateX(100%);
    transition: all 0.3s ease-out;

    .modal-header {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 2.4rem 2.4rem 0;

      .modal-title-wrp {
        border-bottom: 0.1rem solid $grey3;
        padding-bottom: 1.6rem;
        width: 100%;

        h3 {
          font-size: 2rem;
          line-height: 2.8rem;
        }
      }
    }

    .modal__closeIcon {
      position: absolute;
      top: 1.3rem;
      right: 1.3rem;
      font-size: 2em;
      cursor: pointer;
    }

    .modal-content {
      height: calc(100vh - 9.6rem - 4.8rem);
      overflow: auto;
    }

    .modal-footer {
      position: fixed;
      bottom: 0;
      padding: 2.4rem;
      background: $white;
      width: 100%;
    }

    &.resize-modal-loyalty {
      width: 60vw;
    }

    &.right-modal-attribute {
      right: 0;
      position: absolute;
      height: 100vh;
    }
  }
}

.tingle-modal-box {
  position: relative;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 80vw;
  max-width: 40rem;
  background: $white;
  cursor: auto;
  overflow-y: auto;
  transform: translateX(100%);
  transition: all 0.3s ease-out;

  &__content {
    .modal-body {
      margin: auto;

      >*:not(:first-child, :last-child) {
        margin: 0.8rem 0;
      }
    }

    button {
      margin-bottom: 0;

      &.js-apply-coupon {
        margin-bottom: 4.2rem;
      }
    }

    .bottom-buffer {
      margin-bottom: 10vh;
    }
  }

  .modal__closeIcon {
    position: absolute;
    top: 1.3rem;
    right: 1.3rem;
    font-size: 2em;
    cursor: pointer;
  }

  &__footer {
    padding: 1rem 2rem;
    width: auto;
    cursor: auto;
    text-align: right;

    &:after {
      display: table;
      clear: both;
      content: '';
    }

    &--sticky {
      position: fixed;
      bottom: -20rem; // TODO: find a better way
      z-index: 100010;
      opacity: 1;
      transition: all 0.3s ease-out;
    }

    button {
      margin-bottom: 0;
    }
  }

  &.resize-modal-loyalty {
    width: 60vw;
  }
}