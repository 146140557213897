.wishlist {
  &__products-listing {
    background: white;
    border-radius: 0.4rem;
    border: 0.1rem solid $medium_grey;
  }

  &__action-line {
    bottom: 0;
    position: absolute;
    display: flex;

    button:not(:last-child) {
      margin-right: 2.4rem;
    }

    >button {
      display: inline-flex;
      align-items: center;
    }
  }

  &__single-product {
    display: flex;
    justify-content: space-between;
    padding: 2.4rem 0;
    margin: 0 2.4rem;
    background-color: $white;

    &:not(:last-child) {
      border-bottom: 0.1rem solid $light_grey;
    }

    &__img-holder {
      flex-basis: 15rem;
      height: auto;
      max-width: 11rem;

      img {
        width: 100%;
      }
    }

    &__product-info {
      padding-left: 1.6rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      padding-bottom: 2.4rem;

      h3 {
        font-size: 1.4rem;
        line-height: 2rem;

        font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
      }

      &__name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.4rem;
      }

      &__to-add {
        color: $dark_grey;
      }
    }

    .btn-addtobag {
      margin-top: auto;
      padding: 0 0 0 2.2rem;
      text-align: left;
      background-color: transparent;
      color: $black;
      display: flex;
      height: auto;
      width: auto;
      justify-content: flex-start;

      span {
        text-transform: none;
        // font-weight: normal;
      }

      &::before {
        content: '';
        @include fixedSprite('bag');
        @include center('vertical');
        left: 0;
        width: 1.6rem;
        height: 1.6rem;
      }

      &:hover {
        opacity: 0.5;
        transition: opacity 0.5s ease-in-out;

        span {
          &::before {
            transform: none;
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
          }

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

[data-whatintent='keyboard'] {
  .wishlist__single-product__remove-btn:focus {
    outline: 0.1rem solid white;
    text-decoration: initial;
    text-underline-position: initial;
  }

  // }
}


.whishlist-selectsize.modal {
  transform: translate(0);
  height: 100%;
  max-height: 100%;
  width: 41.4rem;
  min-width: auto;
  left: unset;
  right: 0;
  top: 0;
  border-left: 0.1rem solid $medium_grey;
  padding: 0;

  .modal__close {
    padding: 2.8rem 2.4rem;
  }

  .selector__menu {
    padding: 0;
  }

  .modal__body {
    padding: 0 2.4rem 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .product-selection__ctas {
      bottom: 0;
      position: absolute;
      left: 0;
      width: 100%;
      padding: 2.4rem 2.4rem;
      border-top: 0.1rem solid $light_grey;
    }
  }

  .button.button--primary {
    width: 100%;
  }

  .dialog__title {
    padding: 2rem 2.4rem;
  }

  .modal__header {
    display: block;
    border-bottom: 0.1rem solid $light_grey;
  }
}