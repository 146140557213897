html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;

  &.initial-scroll {
    scroll-behavior: initial;
  }
}

body {
  @extend %primary-xs;
  color: $black;
  background-color: $white;
  position: relative;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --scrollbar-width: 0.0rem;
  overflow-y: scroll;
  overflow-x: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $overlay;
    opacity: 0;
    pointer-events: none;
    @include z(overlay);
    // @include animate(0.15);
    @include animate(0.3);
  }

  &.is-loading {
    .loader {
      opacity: 1;
      visibility: visible;
      z-index: 1100;
    }
  }

  &.overlay {
    &::before {
      opacity: 0.5;
      pointer-events: initial;
    }
  }

  &.no-overflow {
    overflow: hidden;
  }

  &.no-overflow {
    .cookie-bar {
      z-index: 2;
    }
  }
}

#top-page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.1rem;
}

:root {
  --viewport-height: 100vh;
}

main {
  margin-top: calc(var(--nav-wrapper) + var(--ticker-height));
  min-height: calc(var(--viewport-height) - var(--nav-wrapper) - var(--footer-height) - var(--ticker-height));
  display: flex;
  flex-direction: column;

  &.no-padding {
    padding-top: 0;
  }

  &.main--autoheight {
    min-height: calc(100vh - #{$footer-clientservice-height-desktop} - #{$header-height-desktop});
    padding-bottom: $space-unit * 9;
  }

}

img {
  max-width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  height: auto;
}

ul,
ol {
  padding-top: $space-unit * 3;
  padding-bottom: $space-unit * 3;
  list-style-position: outside;
  list-style-type: square;
  padding-left: 1.3rem;

  li {
    padding: $space-unit 0;
  }
}

a {
  color: $black;
}


button,
[role='button'],
[role='option'] {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

input[type='search']::-webkit-search-cancel-button {
  /* Remove default cancel button */
  -webkit-appearance: none;
}

.greyed {
  opacity: 0.5 !important;
  filter: grayscale(1) !important;
  border-color: #5a5a5a !important;
  pointer-events: none !important;
}

.line-item-divider {
  height: 0.1rem;
  background-color: $light_grey;
  border: none;

  &-medium {
    background-color: $medium_grey;
    border: none;
    height: 0.1rem;
  }
}

header .logo-header {
  line-height: 0;
}