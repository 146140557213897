.modal {
  padding: ($space-unit * 4);
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  background: $white;
  height: 100%;
  width: 100%;
  overflow: auto;

  &.modal--small {
    max-width: 50rem;
  }

  &.sizevariation-modal {
    height: auto;
  }

  &#filters-modal {
    opacity: 0;
    visibility: hidden;

    &.is-open {
      @include z('modal');
      opacity: 1;
      visibility: visible;
    }
  }

  // overflow-x: hidden;
  &.modal--no-padding {
    padding: 0;
  }

  &__body {
    max-height: 70vh;
    height: auto;
  }

  &.modal--centered {
    text-align: center;
    min-height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 68.6rem;
    min-width: 40%;
  }

  &.modal--minheight {
    min-height: 90vh;
    display: flex;
    align-items: stretch;

    .modal__body {
      width: 100%;
    }
  }

  .withimage {
    .modal__body {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: 100%;
      height: 100%;
      min-height: 30rem;
    }

    .modal__right {
      overflow: hidden;
      margin-top: 2.2rem;
      padding-left: $space-unit * 4;
      padding-right: $space-unit * 4;
      display: flex;
      flex-direction: column;
    }

    .modal__left {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $black;
    }
  }
}

.modal__header {
  display: flex;
}

.ReactModal__Body--open {
  overflow: hidden !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0 !important;
}

.modal.profile-modal,
.modal.address-modal {
  max-width: 50rem;
  width: 100%;
  min-width: unset;
  padding: 0;
  border-radius: 0.4rem;

  .modal__header {
    padding: 0;
    border-bottom: 0.1rem solid $light_grey;

    .dialog__title {
      padding: 2.4rem;
    }
    
    .modal__close {
      padding: 3.1rem;
    }
  }

  .modal__body {
    .edit-profile-form-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      padding: 2.4rem;

      .form-group {

        select {
          padding-left: 1.8rem;
          padding-right: 1.8rem;
        }
        
        .form-control {
          &:has(input[type="date"]) {
            &:after {
              content: '';
              pointer-events: none;
              @include fixedSprite('calendar');
              position: absolute;
              right: 1.8rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          input {
            &:not([type=radio]) {
              padding: 2.4rem 1.8rem 0.6rem;
              border-color: $grey_label;  
  
              &:read-only {
                opacity: 1;
              }    
              
              &+label {
                transform: translate(0, -1.2rem) scale(0.9);
                cursor: pointer;
                opacity: 1;
                color: $grey_label;
                padding-left: 2.2rem;
              }              
            }  
          }
        }
  
      }
    }

    .button-wrapper {
      padding: 0 2.4rem 2.4rem;
    }

    .globalerrors.invalid-feedback {
      margin-left: 2.4rem;
      margin-bottom: 0;
      margin-top: 2.4rem;
    }
  }
}

.modal__divisor {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  border: none;
  @include border('top');

  &.noabs {
    position: relative;
  }
}

.dialog__title {
  padding-bottom: 1.6rem;
}

.dialog__description {
  line-height: 1.6rem;
  margin-top: -0.6rem;
  margin-bottom: ($space-unit * 6);
}

.modal__close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 2.4rem;
  z-index: 3;

  &::before {
    content: '';
    @include fixedSprite('close-16');
    display: block;
  }

  &:focus {
    outline: transparent;
  }
}

.modal__intro {
  margin: ($space-unit * 6) 0;

  .status {
    @extend %primary-s;
    display: block;
    margin-bottom: ($space-unit * 2);
  }
}

.overlay-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $black_secondary;
  @include z(modal);
}

.country-modal,
.language-wrapper {

  ul,
  ol {
    @include reset-list;

    li {
      a {
        @extend %primary-s;
        @include del-link;
        padding: ($space-unit) 0;
        text-transform: capitalize;
        display: inline-flex;
        align-items: center;

        .countryname {
          margin-right: 0.6rem;
          display: inline-block;
        }

        .icon--bag {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  @include animate(0.4, 'opacity');

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Content {

  transform: translate(-50%, -50%) translateY(3.2rem);
  @include animate(0.4, 'transform');

  &--after-open {

    transform: translate(-50%, -50%);
  }

  &--before-close {

    transform: translate(-50%, -50%) translateY(3.2rem);
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {

    transform: translateY(15%);
  }

  to {

    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {

    transform: translateY(0);
  }

  to {

    transform: translateY(-10%);
  }
}

.switchView {
  display: flex;
  gap: 1.6rem;
}

#button-filter-modal-horizontal {
  color: $dark_grey;
  padding: 0 2.4rem;
}

#button-filter-modal-horizontal.selected {
  color: $blue;

  & span::after {
    @include fixedSprite('filters');
  }
}

.refinement-sustainibility {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: $black;
  width: 100%;
  font-size: 1.4rem;

  font-weight: bold;
  padding: 2.4rem 0;
}

.modal-promo {

  .promo-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: rgba(34, 34, 34, 0.5);
    z-index: 7;

    transition: all 0.3s 0s cubic-bezier(0.25, 1, 0.5, 1);
    visibility: visible;
    opacity: 1;

    &.closed {
      opacity: 0;
      visibility: hidden;

      &+.promos-wrapper {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .promos-wrapper {
    display: flex;
    position: fixed;
    top: var(--ticker-height);
    right: 0;
    flex-direction: column;
    width: 41.4rem;
    height: calc(var(--viewport-height) - var(--ticker-height));
    padding: 0;
    opacity: 1;
    visibility: visible;
    overflow: hidden;

    transition: all .6s cubic-bezier(0.1, 0.3, 0.4, 1);
    z-index: 7;
    background-color: $white;

    .title {
      padding: 2rem 2.4rem;
      border-bottom: 0.1rem solid $light_grey;

      .promo-close {
        position: absolute;
        right: 2.4rem;
        top: 2.4rem;
      }
    }

    .slot-wrapper {
      padding: 2.4rem;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      overflow: auto;
      max-height: calc(100% - 7.3rem);
      height: 100%;

      .promo-container {
        background-color: $promo_pink;
        padding: 2.4rem;
        color: $light_orange;
        border-radius: 0.4rem;

        .copy-button {
          text-align: center;
          border: 0.2rem solid white;
          padding: 1.2rem;
        }
      }
    }

  }

  .modal-isml {
    display: block;

    .modal-overlay {
      position: fixed;
      bottom: 0;
      right: 0;
      margin: 4rem;
      top: unset;
      left: unset;
      background: transparent;
      z-index: 1;

      .modal-container {
        max-width: 35rem;
        width: 35rem;
        border: 0.1rem solid $medium_grey;

        .modal-close {
          display: none;
        }

        .modal-content {
          padding: 1.6rem 2rem;
          cursor: pointer;

          .promo-popup-wrapper {
            .icon--plus1::after {
              position: absolute;
              right: 2rem;
              top: 1.8rem;
            }

            .button--gift-b--left::before {
              vertical-align: text-top;
            }

            .counter {
              margin-left: 0.4rem;
            }
          }
        }
      }
    }
  }
}

.modal-isml {
  display: none;

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: auto;
    z-index: 999;
  }

  .modal-container {
    position: relative;
    background-color: $white;
    padding: 0;
    max-width: 50rem;
    width: 100%;
    max-height: 100vh;
    border-radius: 0.4rem;
    overflow-y: hidden;
    box-sizing: border-box;
    will-change: auto;

    &.tiny {
      max-width: 38rem;
    }
  }

  .modal-close {
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    display: flex;

    &::after {
      content: '';
      @include fixedSprite(close-16);
    }
  }

  .modal-header {
    padding: 2.4rem 6.4rem 1.6rem 2.4rem;
    border-bottom: 0.1rem solid $light_grey;
  }

  .modal-footer {
    margin-top: 2rem;
  }

  .modal-footer-buttons {
    button {
      width: 100%;
    }
  }

  .modal-content {
    padding: 2.4rem;
    padding-top: 0;
    height: calc(100% - 6.2rem);
    overflow-y: auto;
  }

  &.modal-full-width {
    .modal-container {
      max-width: 100vw;
    }
  }

  &.modal-left,
  &.modal-right {
    .modal-container {
      border-radius: 0;
    }

    .modal-header {
      @extend %primary-l;
    }
  }

  &.modal-left {
    .modal-overlay {
      justify-content: flex-start;
      align-items: stretch;
    }
  }

  &.modal-right {
    .modal-overlay {
      justify-content: flex-end;
      align-items: stretch;
    }
  }

  &.modal-bottom {
    bottom: 0;

    .modal-overlay {
      background: transparent;
      top: inherit;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .modal-container {
      border-radius: 0;
      box-shadow: 0 0 1.1rem rgba(0, 0, 0, 0.25);
    }
  }

  @media (max-width: get($grid-breakpoints, sm)-1) {
    .modal-container.newsletter-foreign-modal {
      max-width: 100%;
    }

    &.modal-md-bottom {
      bottom: 0;

      .modal-overlay {
        background: transparent;
        top: inherit;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .modal-container {
        border-radius: 0;
        box-shadow: 0 0 1.1rem rgba(0, 0, 0, 0.25);
      }
    }
  }

  &.map-modal {
    .modal-container {
      width: 100%;
      height: 100%;
      max-width: 71rem;
      max-height: 58rem;
    }
  }

  &.is-open {
    display: block;
  }

  &[aria-hidden='false'] {
    .modal-overlay {
      animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    .modal-container {
      animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  &[aria-hidden='true'] {
    .modal-overlay {
      animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }

    .modal-container {
      animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  .accordion__panel {
    position: relative;
  }

  .dropdown .values {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }

  .accordion__header[aria-expanded="true"]+.accordion__panel {
    border-bottom: 0.1rem solid $blue;
  }

}