.separator {
  height: 10rem;
}

.uikit .typescale div {
  min-height: 8rem;
  line-height: 8rem;
}

.uikit .typescale div:not(.col-6, .row) {
  border-bottom: 0.1rem solid $dark_grey;
}

.uikit .uixgap {
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid $medium_grey;
}

.divider {
  position: relative;
  margin-top: 5rem;
  margin-bottom: 5rem;
  height: 0.2rem;
  background-color: transparent;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
}

.div-transparent:before {
  content: '';
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 0.2rem;
  background-image: linear-gradient(to right, transparent, rgb(48, 49, 51), transparent);
}

.flex-v {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex .boxed {
  max-width: max-content;
}

span .center {
  display: table;
}

.boxed {
  padding: 1rem;
  border: 0.1rem solid $dark_grey;
  border-radius: 0.5rem;
  max-width: 30%;
}

.box {
  &-center {
    margin-left: auto;
    margin-right: auto;
  }

  &-right {
    margin-left: auto;
  }

  &-left {
    margin-right: auto;
  }
}

.flex {
  &-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}