.search-container {
  .search-field {
    margin: 1.6rem 1.6rem 0;
    position: sticky;
    top: 1.6rem;
    background-color: $white;
  }

  .results-container {
    width: 100%;
    border-top: initial;
    gap: 3.2rem;

    .js-discount-msg {
      margin-left: 0.5rem;
    }


    .submenu-heading {
      margin-bottom: 2.4rem;
    }

    .submenu-recent-popular {
      flex-direction: column;

      .submenu-recent,
      .submenu-popular {
        width: 100%;
      }

      .submenu-popular {
        border-top: 0.1rem solid $light_grey;
        padding-top: 3.2rem;
      }
    }

    .submenu-group {
      border-bottom: 0.1rem solid $medium_grey;
      border-top: 0;

      &.minitile-group {
        border: 0;
      }

      ul {
        grid-template-columns: 100%;
        flex-wrap: nowrap;
        height: auto;

        li {
          grid-column-end: initial;
        }
      }

    }

  }

  .wrapper-search {
    position: fixed;
    z-index: 2;
    height: var(--viewport-height);
    top: 0;
    border-radius: initial;
    overflow-y: auto;
  }

  .minitile-group {
    gap: 2.4rem;
    grid-template-columns: 1fr;
  }

  .results-container a {
    flex-basis: 0%;
  }

  #searchResults .results-container {
    height: calc(100vh - 9rem);
    padding: 2.4rem;
    overflow: auto;
  }
}