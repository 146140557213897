#minibag-container {
  display: none;

  .overlay {
    pointer-events: all;
  }

  .minibag {
    top: 100%;
    width: 46%;
    padding: $space-unit * 4;
    padding-top: 2.2rem;

    &::after {
      top: 0;
    }

    &__title {
      padding-bottom: 1.4rem;

      >p {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
  }
}


.feedbackbag-wrapper[data-visible=true] #feedbackbag-container .overlay-feedbackbag .feedbackbag {
  right: unset;
  max-width: 90vw;
}

.overlay-feedbackbag {
  display: flex;
  justify-content: center;

  .feedbackbag {
    .CTA.button--delete-blue--left {
      padding: 0;
      width: auto;
    }
  }
}

.feedbackbag-wrapper[data-visible=true] #feedbackbag-container .overlay-feedbackbag .feedbackbag .feedbackbag__buttons__wrapper {
  display: grid;
  grid-template-columns: 47% 47%;
  grid-column-gap: 1.6rem;
  overflow: hidden;
  justify-content: space-between;

  .feedbackbag__btn {
    width: 100%;
    padding: 0 1.2rem;

  }
}