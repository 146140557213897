.search-container {
  display: flex;

  .wrapper-search {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $white;
    @include z;
  }

  .search-field {
    position: relative;
    display: flex;
    align-items: center;
    height: 6rem;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;

    // border-bottom: 0.1rem solid $grey;
    &::after {
      content: '';
      display: block;
      @include fixedSprite('search');
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 4rem;

      transform: translateY(-50%);
      @include z;
    }

    input {
      padding: 0 2.4rem 0 6.4rem;
      font-size: 1.6rem;

      line-height: 2.4rem;
      font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
      appearance: initial;
      border: initial;
      background-color: initial;
      flex-grow: 1;
      height: 100%;
    }

    button {
      height: 100%;
      width: 5.6rem;
      background-size: 1.6rem;
      border-left: 0.1rem solid $light_grey;
    }
  }

  .results-container {
    border-top: 0.1rem solid $grey;
    padding: 3.2rem 4rem 2.4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;

    a {
      @include del-link;
      flex-basis: 48%;
    }
  }

  .suggestion-item {
    color: $dark_grey;

    b {
      color: $black;
    }
  }

  .minitile-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.6rem;
    height: fit-content;
  }

  .search-minitile {
    display: flex;
    gap: 1.6rem;
    outline-offset: 0.1rem;

    .img-wrapper {
      display: block;
      width: 10rem;
      height: auto;
      background: $light_grey;
    }

    img {
      width: inherit;
      -webkit-filter: blur(.5rem);
      filter: blur(.5rem);
      transition: filter 400ms, -webkit-filter 400ms;

      &.lazyloaded {
        -webkit-filter: blur(0);
        filter: blur(0);
      }
    }

    .minitile-info {
      flex: 1;
      padding: 0;
      line-height: 2.1rem;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 0.8rem;

      h3 span {
        display: block;
        color: $dark_grey;
        margin-top: $space-unit;
      }

      .js-discount-msg {
        margin-left: 0.8rem;
      }
    }
  }

  .submenu-recent-popular {
    display: flex;

    .submenu-recent,
    .submenu-popular {
      width: 50%;
    }
  }

  .submenu-group ul {
    height: 24rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}