// https://www.w3.org/TR/wai-aria-practices-1.1/examples/radio/radio-1/radio-1.html

.radiobox {
  &--horizontal-two-items {
    [role='radio'] {
      @include col-width(6);
    }
  }

  &--horizontal-more-items {
    [role='radio'] {
      @include col-width(4);
    }
  }

  &--vertical {
    [role='radio'] {
      @include col-width(4);
    }
  }
}
