.modal__header,
.whishlist-selectsize.modal .modal__header,
.sizevariation-modal.whishlist-selectsize.modal .modal__header {
  position: sticky;
  top: 0;
  right: 0;
  background-color: $white;
  justify-content: center;
  //padding-top: 0;
  @include z;

  .product-selection__wrapper {
    margin: 0;
  }

  .dialog__title {
    padding: 0;
    display: flex;
    width: 100%;
    align-items: center;
  }

  .dialog__description {
    margin-top: ($space-unit * 6);
    margin-bottom: ($space-unit * 4);
  }
}

.modal__body {
  .accordion__group {
    padding-top: ($space-unit * 7);
  }
}

.modal__divisor {
  display: none;
}

.modal {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: none;

  &.sizevariation-modal {
    bottom: 0;
    top: unset;
    max-width: 100%;
  }
}

.ReactModal__Content {
  transition-property: opacity;
  transition-delay: 150ms;
  opacity: 0;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Overlay {
  &.modal-line-overlay::before {
    content: '';
    width: 12rem;
    height: 0.2rem;
    position: absolute;
    top: 6.6rem;
    left: 50%;

    transform: translateX(-50%);
    background-color: $white;
  }
}

.modal.profile-modal {
  top: unset;
  bottom: 0;
  height: fit-content;
  width: 100%;
  max-width: unset;
  padding: 0;
  border-radius: 0.4rem;

  .edit-profile-form {
    .edit-profile-form-wrapper {
      padding: 1.8rem 2.4rem;
    }

    .button-wrapper {
      padding: 2rem 2.4rem;
      border-top: 0.1rem solid $light_grey;
    }
  }
}

.modal-promo {
  .modal-isml {
    .modal-overlay {
      margin: 2.4rem 2.4rem 4.8rem;
      left: 0;
    }
  }

  .promos-wrapper {
    width: 100vw;
    height: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 20vh;
    bottom: 0;
  }
}