.modal__close {
  &.modal__close--inverted-sm {
    &::before {
      @include fixedSprite('close-white');
    }
  }
}

.country-modal {
  .accordion__panel {

    ul,
    ol {
      padding-bottom: ($space-unit * 10);
    }
  }
}

.modal {
  .withimage {
    &__body {
      grid-template-columns: 100%;
    }

    &__left {
      display: none;
    }
  }
}

.button-row {
  margin-top: ($space-unit * 12);
}

.modal__header,
.whishlist-selectsize.modal .modal__header {
  position: sticky;
  top: 0;
  right: 0;
  background-color: $white;
  justify-content: center;
  padding-top: 0;
  @include z;

  .product-selection__wrapper {
    margin: 0;
  }

  .modal__close {
    padding: 2.2rem;
  }

  .dialog__title {
    padding: 0;
    display: flex;
    width: 100%;
    align-items: center;
  }

  .dialog__description {
    margin-top: ($space-unit * 6);
    margin-bottom: ($space-unit * 4);
  }
}

.modal__body {
  .accordion__group {
    padding-top: ($space-unit * 7);
  }
}

.modal__divisor {
  display: none;
}

.modal {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: none;

  &.sizevariation-modal {
    bottom: 0;
    top: unset;
    max-width: 100%;
  }
}

.ReactModal__Content {
  transition-property: opacity;
  transition-delay: 150ms;
  opacity: 0;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Overlay {
  &.modal-line-overlay::before {
    content: '';
    width: 12rem;
    height: 0.2rem;
    position: absolute;
    top: 6.6rem;
    left: 50%;

    transform: translateX(-50%);
    background-color: $white;
  }
}

.modal.profile-modal {
  top: unset;
  bottom: 0;
  height: fit-content;
  width: 100%;
  max-width: unset;
  padding: 0;
  border-radius: 0.4rem;

  .modal__body {
    max-height: calc(var(--viewport-height) - 7.6rem);
  }

  .edit-profile-form {
    .edit-profile-form-wrapper {
      padding: 1.8rem 2.4rem;

      .form-group {
        select {
          padding-left: 1.6rem;
          padding-right: 1.6rem;
        }

        .form-control {
          &:after {
            right: 1.6rem;
          }

          input{
            &:not([type=radio]) {
              padding-left: 1.6rem;
              padding-right: 1.6rem;

              &+label {
                padding-left: 1.8rem;
              }              
            }  
            

          }
        }
      }
    }

    .button-wrapper {
      padding: 2rem 2.4rem;
      border-top: 0.1rem solid $light_grey;
    }

    &__gender {
      margin-bottom: 0.8rem;
      .radio-wrapper {
        flex-direction: column;
        gap: 1.6rem;
      }
    }
  }
}

.modal-promo {
  .modal-isml {
    .modal-overlay {
      margin: 2.4rem 2.4rem 4.8rem;
      left: 0;
    }
  }

  .promos-wrapper {
    width: 100vw;
    height: auto;
    position: fixed;
    left: 0;
    right: 0;
    top: 20vh;
    bottom: 0;
  }
}