.accordion-mini__header {
  @extend %primary-xs;
  position: relative;
  text-decoration: none;
  color: $black;
  text-align: left;
  width: 100%;

  &::after {
    content: '';

    transform: translateY(-50%);
    position: absolute;
    right: 0;
    top: 50%;
    @include fixedSprite('chevron-down-small');
  }

  &[aria-expanded='false'] {
    &+.accordion-mini__panel {
      height: 0;
      visibility: hidden;
    }
  }

  &[aria-expanded='true'] {
    &::after {

      transform: translateY(-50%) rotate(180deg);
    }
  }
}

.accordion-mini__panel {
  overflow: hidden;
  visibility: visible;
  @include animate;

  >.row {
    padding-top: ($space-unit * 2);
  }
}