.thankyouLogin {
  &__container {
    max-width: 100%;
    margin: 4.8rem 0 5.6rem;
    padding: 0 2.4rem;
  }

  &__btn {
    max-width: 100%;
  }
}

#account-thankyouregister {
  .product-carousel {
    &-wrapper {
      padding: 4rem 0 3.2rem;
      background-color: $white;
      margin-top: 5.6rem;
    }

    &__title {
      font-size: 2rem;
      line-height: 2.8rem;
    }
  }
}