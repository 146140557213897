.login-registration {
  padding: 3.2rem 0;
  max-width: 100%;

  &__tabs {
    max-width: 57vw;
    margin: 0 auto;
  }

  &__section {
    &__registration {
      &__list {
        padding: 2.4rem 1.4rem;
      }
    }
  }
}