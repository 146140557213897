.product-image {
  @include fixedRatio;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #e1e2df; // similar to image background
  > .swiper-wrapper {
    position: absolute;
  }
}
