.embedded-service #esw-fab.showAnimation,
.embedded-service #esw-channelmenu.active {
  z-index: 4;
  left: 3rem;
}

.embedded-service .channelmenu_ctas>li[class~="chatDisabled"] {
  display: none !important;
}

header#esw-channelmenu_header {
  top: 0;
}

#embeddedMessagingConversationButton.embeddedMessagingConversationButton,
.embeddedMessagingFrame#embeddedMessagingFrame {
  left: 3rem;
  right: 0;
}