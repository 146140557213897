.form-group {
  &--gender-radio {
    .radio {
      flex-basis: 50%;
    }
  }

  input[type='date'][value='']:empty:not(:focus):after {
    color: $dark_grey;
    content: attr(placeholder);
    position: absolute;
    left: 0;
    min-height: 2rem;
    padding: 1.5rem 1.2rem;
    font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    line-height: 1.8rem;

  }

  input[type='date'][value='']:empty:not(:focus)::-webkit-datetime-edit-ampm-field,
  input[type='date'][value='']:empty:not(:focus)::-webkit-datetime-edit-day-field,
  input[type='date'][value='']:empty:not(:focus)::-webkit-datetime-edit-hour-field,
  input[type='date'][value='']:empty:not(:focus)::-webkit-datetime-edit-millisecond-field,
  input[type='date'][value='']:empty:not(:focus)::-webkit-datetime-edit-minute-field,
  input[type='date'][value='']:empty:not(:focus)::-webkit-datetime-edit-month-field,
  input[type='date'][value='']:empty:not(:focus)::-webkit-datetime-edit-second-field,
  input[type='date'][value='']:empty:not(:focus)::-webkit-datetime-edit-week-field,
  input[type='date'][value='']:empty:not(:focus)::-webkit-datetime-edit-year-field,
  input[type='date'][value='']:empty:not(:focus)::-webkit-datetime-edit-text {
    color: transparent;
  }
}