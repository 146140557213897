.mobile {
  @media (max-width: 599px) {
    padding-top: 150%;
  }
}

.default {
  content: '';
  display: block;
  width: 100%;
}
