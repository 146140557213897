*:focus {
  outline-offset: 0;
  outline: transparent;
}
[data-whatintent='mouse'],
[data-whatintent='touch'] {
  *:focus {
    outline: transparent;
  }
}

.visually-hidden {
  position: absolute !important;
  height: 0.1rem;
  width: 0.1rem;
  overflow: hidden;
  clip: rect(0.1rem 0.1rem 0.1rem 0.1rem);
  white-space: nowrap;
}

.sr-only {
  @extend .visually-hidden;
}

.visible-on-focus {
  &:not(:focus):not(:active):not(:focus-within) {
    @extend .visually-hidden;
  }
}
