.form-control {
  position: relative;

  input,
  textarea {

    &:disabled,
    &:read-only {
      cursor: none;
      pointer-events: none;
      opacity: $input-disabled-opacity;
      border-color: rgba($grey_input, $input-disabled-opacity);

      &+label {
        opacity: 0.3;
      }
    }

    &::placeholder {
      color: transparent;
      user-select: none;
    }

    &:focus {
      border-color: $blue;
    }

    &:-webkit-autofill~label,
    &:focus~label,
    &.label-up~label {
      top: 0.6rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  &--outline {
    input {
      border: none;
      border-bottom: $space-line solid $input-outline;
      background-color: $white;
      border-radius: 0;
    }
  }
}

textarea {
  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 2rem;
}

textarea.form-control {
  display: block;
  height: ($space-unit * 7) * 4;
  margin-bottom: 0;
  border: 0.1rem solid $medium_grey;
  padding: 1.2rem;
  width: 100%;
  border-radius: 0.4rem;

  &:not(:placeholder-shown):not(:focus)+label {
    opacity: 0;
  }

  &::placeholder {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  &:focus+label {
    opacity: 0;
  }

  &+label {
    position: absolute;
    top: 1.5rem;
    left: 1.2rem;
    color: $dark_grey;
    opacity: 1;

    transition: all 0.4s $easeOutQuart;
  }
}

.disable-resize {
  resize: none;
}

.valid-tick {
  content: '';
  right: $space-unit * 2;
  width: ($space-unit * 2);
  height: ($space-unit * 2);
  @include fixedSprite('checkmark');
  position: absolute;
  top: 50%;

  transform: translateY(-50%);
  display: none;
}

.is-valid {
  .valid-tick {
    display: inline-block;
  }
}

.form-button-container {
  display: flex;

  .button {
    margin-top: 0 !important;
  }
}

.show-password {
  @extend %primary-xxs;
  position: absolute;
  right: 1.7rem;
  top: 50%;
  transform: translateY(-50%);

  &.has-tick {
    right: ($space-unit * 8);
  }
}