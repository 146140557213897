[data-component='BenefitBarComponent'] {
  .benefitBar{
    &-body{
      &-group{
        padding: 4.8rem 2.8rem;
      }
    }

    &-image{
      &-child{
        .image{
          padding: 4rem 20vw 4rem 4rem;
          min-height: unset;
          height: 100%;
          background-position: right;
          background-size: cover;

          .title{
            font-size: 2.4rem;
          }
        }
      }
    }
  }
}